
// outsource dependencies
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { reducer as toastr } from 'react-redux-toastr';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as ctrlReducer, sagas as ctrlSaga, path } from 'redux-saga-controller';

// local dependencies
import { config } from '../constant';

// Build the middleware to run our Sagas
const sagaMiddleware = createSagaMiddleware();

// Apply "redux" extension compose for non production environment
const enchantedCompose = !config('DEBUG', false)
  ? compose
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsDenylist: ['@CSD']
    }) : compose;

// Create store outside of root to be able dispatch actions from anywhere!
export const store = createStore(
  combineReducers({
    [path]: ctrlReducer,
    toastr,
    form,
    // NOTE it may be "redux" libraries
  }),
  enchantedCompose(applyMiddleware(sagaMiddleware))
);

// initialize saga
sagaMiddleware.run(ctrlSaga);
