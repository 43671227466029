
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { InputGroup, Button, InputGroupAddon, Input, DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, PaginationItem, PaginationLink, CustomInput, Pagination as BootstrapPagination } from 'reactstrap';

// local dependencies
import { FaIcon, SorDirection } from './icon';


export const SearchInput = memo(function SearchInput ({ value, disabled, placeholder, onClear, onApply, onChange }) {

  const handleChange = useCallback(event => {
    const value = String(event.target.value).trimStart();
    onChange(value);
    if (!value) {
      onClear();
    }
  }, [onChange, onClear]);

  const handleKeyDown = useCallback(event => {
    if (event.keyCode === 27) {
      onClear();
      event.target.blur();
      event.preventDefault();
    } else if (event.keyCode === 13) {
      onApply();
      event.target.blur();
      event.preventDefault();
    }
  }, [onClear, onApply]);

  return <InputGroup>
    { value && <InputGroupAddon addonType="prepend">
      <Button type="button" disabled={disabled} onClick={onClear}>
        <FaIcon icon="times" />
      </Button>
    </InputGroupAddon>}
    <Input
      value={value}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
    />
    <InputGroupAddon addonType="append">
      <Button type="button" color="primary" disabled={disabled} onClick={onApply}>
        <FaIcon icon="search" />
      </Button>
    </InputGroupAddon>
  </InputGroup>;
});
SearchInput.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
SearchInput.defaultProps = {
  placeholder: '\u2315 Search',
  disabled: false,
  value: ''
};

export const SortBy = memo(function SortBy ({ field, sortF, sortD, onChange, children, ...attr }) {

  const handleChange = useCallback(() => onChange({
    sortF: field,
    sortD: field === sortF ? !sortD: true
  }), [sortF, sortD, field, onChange]);

  return <Button className="text-nowrap p-0" outline color="link" { ...attr } onClick={handleChange}>
    <SorDirection status={field === sortF ? sortD : null} />
    { children }
  </Button>;
});
SortBy.propTypes = {
  disabled: PropTypes.bool,
  sortD: PropTypes.bool.isRequired,
  sortF: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};
SortBy.defaultProps = {
  disabled: false,
};

export const PageSize = memo(function PageSize ({ value, options, onChange, size, ...attr }) {
  return <UncontrolledDropdown size={size}>
    <DropdownToggle caret { ...attr }> { value } </DropdownToggle>
    <DropdownMenu>
      { (options || [10, 15, 30, 50]).map(item => <DropdownItem
        key={item}
        disabled={value === item}
        onClick={() => onChange(item)}
      >
        { item }&nbsp;Items
      </DropdownItem>) }
    </DropdownMenu>
  </UncontrolledDropdown>;
});
PageSize.propTypes = {
  options: PropTypes.array,
  size: PropTypes.oneOf(['sm', 'lg']),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
PageSize.defaultProps = {
  size: null,
  options: null
};

const BREAK = 'BREAK';
// FIXME If you know how to improve, please do not hold back.
const generateSpreadPages = (page, total) => {
  const result = [];
  result.push(0);
  result.push(1);
  if (page < 4 || page > total - 4) {
    result.push(2);
    result.push(3);
    result.push(BREAK);
    let key = 4;
    while (--key) {
      result.push(total - key);
    }
    return result;
  }
  result.push(BREAK);
  result.push(page - 1);
  result.push(page);
  result.push(page + 1);
  result.push(BREAK);
  result.push(total - 1);
  // result.push(total);
  return result;
};
export const Pagination = memo(function Pagination ({ value, totalPages, onChange, disabled, ...attr }) {
  const prevDisabled = disabled || value < 1;
  const handlePrev = useCallback(() => !prevDisabled && onChange(value - 1), [value, prevDisabled, onChange]);
  const nextDisabled = disabled || value >= totalPages - 1;
  const handleNext = useCallback(() => !nextDisabled && onChange(value + 1), [value, nextDisabled, onChange]);
  const handleChange = useCallback(page => !disabled && value !== page && onChange(page), [value, disabled, onChange]);

  let list = [];
  if (totalPages > 10) {
    list = generateSpreadPages(value, totalPages);
  } else if (totalPages > 0) {
    let key = totalPages;
    while (key--) { list.unshift(key); }
  }

  return <BootstrapPagination listClassName="m-0" aria-label="Pagination" {...attr} >
    <PaginationItem disabled={prevDisabled} onClick={handlePrev}>
      <PaginationLink previous> Previous </PaginationLink>
    </PaginationItem>
    {list.map((page, i) => (page === BREAK ? <PaginationItem key={`${BREAK}-${i}`} disabled>
      <PaginationLink> ... </PaginationLink>
    </PaginationItem> : <PaginationItem
      key={page}
      disabled={disabled}
      active={page === value}
      onClick={() => handleChange(page)}
    >
      <PaginationLink> { page + 1 } </PaginationLink>
    </PaginationItem>))}
    <PaginationItem disabled={nextDisabled} onClick={handleNext}>
      <PaginationLink next> Next </PaginationLink>
    </PaginationItem>
  </BootstrapPagination>;
});
Pagination.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};
Pagination.defaultProps = {
  disabled: false,
};

export const RowCheckbox = memo(function RowCheckbox ({ checked, onChange, ...attr }) {
  const uid = _.uniqueId('RC-');
  const handleChange = useCallback(() => onChange(!checked), [checked, onChange]);

  return <CustomInput id={uid} {...attr} checked={checked} type="checkbox" onChange={handleChange} />;
});
RowCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
