
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Table, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';

// local dependencies
import { Verify } from './verify';
import { propertiesListCtrl } from './controller';
import { formatPrice, truncate, humanize } from '../../services';
import { PROPERTY_RS_TYPE, PROPERTY_INT_STATE } from '../../constant';
import { Preloader, AlertError, SearchInput, SortBy, DotsIcon, Pagination, PageSize, AppTooltip, CheckCircleIcon, MinusCircleIcon, AdIcon, SendIcon, ChartLineIcon, TrashIcon } from '../../component';

// configure
const pageSizeOptions = [10, 20, 30];

export const PropertiesList = memo(function PropertiesList () {
  const [
    { initialized, errorMessage, disabled, list, sortD, sortF, size, page, search, totalPages, status },
    { initialize, updateCtrl, updateFilter, deleteItem, verifyProperty, toggleActivateProperty,
      togglePromoteProperty }
  ] = useController(propertiesListCtrl);

  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => _.map(list, item => {
    const isPromoted = _.get(item, 'promoted');
    const status = _.get(item, 'propertyIntState');
    return {
      ...item,
      isPromoted,
      status: humanize(status),
      isActive: status === PROPERTY_INT_STATE.ACTIVE,
      isInActive: status === PROPERTY_INT_STATE.INACTIVE,
      isAllowVerify: status === PROPERTY_INT_STATE.ON_VERIFICATION,
      isAllowPromote: status === PROPERTY_INT_STATE.ACTIVE && !isPromoted,
      isAllowCancelPromote: status === PROPERTY_INT_STATE.ACTIVE && isPromoted,
      handleDelete: () => deleteItem(item),
      handleVerify: () => verifyProperty(item),
      handleTogglePromote: () => togglePromoteProperty(item),
      handleActivate: () => toggleActivateProperty({ ...item, activate: true }),
      handleDeactivate: () => toggleActivateProperty({ ...item, activate: false }),
      company: _.get(item, 'company.name'),
      propertyType: _.get(item, 'propertyRSType'),
      titleShort: truncate(_.get(item, 'title'), { length: 20 }),
      price: formatPrice(_.get(item, 'price'), _.get(item, 'ccy')),
      isRentProperty: PROPERTY_RS_TYPE.RENT === _.get(item, 'propertyRSType'),
      locationShort: truncate(_.get(item, 'propertyAddress.addressText'), { length: 20 }),
      location: `${_.get(item, 'propertyAddress.country')}, ${_.get(item, 'propertyAddress.city')}`,
    };
  }), [list, deleteItem, verifyProperty, toggleActivateProperty, togglePromoteProperty]);

  const statuses = useMemo(() => _.map(Object.values(PROPERTY_INT_STATE), item => ({
    id: item,
    name: humanize(item),
    onClick: () => updateFilter({ page: 0, status: item })
  })), [updateFilter]);

  // NOTE prepare actions
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);

  return <Container fluid id="PropertiesList" className={cn('properties-list', { 'no-events': disabled })}>
    <Verify />
    <Preloader active={!initialized} className="app-preloader">
      <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
      <Row className="d-flex align-items-center border-bottom mb-3 pb-2">
        <Col xs="3" tag="h2" className="text-primary text-left mb-0"> Properties </Col>
        <Col xs="4">
          <SearchInput
            value={search}
            onApply={handleApply}
            onClear={handleClear}
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="3" className="d-flex justify-content-center">
          <UncontrolledDropdown size="lg">
            <DropdownToggle caret> { humanize(status) } </DropdownToggle>
            <DropdownMenu>
              {/* eslint-disable-next-line max-len */}
              { _.map(statuses, ({ id, name, onClick }) => <DropdownItem disabled={id === status} key={id} onClick={onClick}>
                { name }
              </DropdownItem>) }
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col xs="2">
          <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
        </Col>
      </Row>
      <Table bordered striped>
        <thead>
          <tr>
            <th>
              <SortBy field="title" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Title </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="country" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Location </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="price" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Price </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="companyName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Company </strong>
              </SortBy>
            </th>
            <th>
              <SortBy disabled field="propertyIntState" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Status </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="promoted" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Promoted </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="propertyRSType" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Purpose </strong>
              </SortBy>
            </th>
            <th style={{ width: '1%' }}> </th>
          </tr>
        </thead>
        <tbody>
          { isEmpty ? <tr>
            <td colSpan="8">
              <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
            </td>
          </tr>
            : _.map(prepared, ({
              id, title, titleShort, company, price, propertyType, location, locationShort, status, isActive,
              isRentProperty, isAllowVerify, isInActive, handleDelete, handleVerify, handleActivate,
              handleDeactivate, isPromoted, isAllowCancelPromote, isAllowPromote, handleTogglePromote
            }) => <tr key={id}>
              <AppTooltip content={title} tag="td" className="text-truncate">
                { titleShort }
              </AppTooltip>
              <AppTooltip content={location} tag="td" className="text-truncate">
                { locationShort }
              </AppTooltip>
              <td className="text-nowrap"> { price } </td>
              <td className="text-truncate"> { company } </td>
              <td className="text-truncate"> { status } </td>
              <td className="text-center">
                { isPromoted ? <CheckCircleIcon size="2x" className="text-success" />
                  : <MinusCircleIcon className="text-danger" size="2x" /> }
              </td>
              <td className="text-center va-middle py-0">
                <h5 className="m-0">
                  <Badge pill color={isRentProperty ? 'success' : 'primary'}>
                    { propertyType }
                  </Badge>
                </h5>
              </td>
              <td className="va-middle p-0">
                <UncontrolledDropdown className="d-flex justify-content-center">
                  <DropdownToggle color="none" size="lg">
                    <DotsIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    { isAllowVerify && <DropdownItem onClick={handleVerify} className="py-2">
                      <SendIcon size="lg" className="mr-2 text-primary fa-rotate-270" />
                      Verify
                    </DropdownItem> }
                    { isAllowPromote && <DropdownItem onClick={handleTogglePromote} className="py-2">
                      <AdIcon size="lg" className="mr-2 text-primary" />
                      Promote
                    </DropdownItem> }
                    { isAllowCancelPromote && <DropdownItem onClick={handleTogglePromote} className="py-2">
                      <AdIcon size="lg" className="mr-2 text-danger" />
                      Cancel Promotion
                    </DropdownItem> }
                    { isInActive && <DropdownItem onClick={handleActivate} className="py-2">
                      <ChartLineIcon size="lg" className="mr-2 text-primary" />
                      Activate
                    </DropdownItem> }
                    { !isInActive && <DropdownItem onClick={handleDeactivate} className="py-2">
                      <ChartLineIcon size="lg" className="mr-2 text-danger" />
                      Deactivate
                    </DropdownItem> }
                    <DropdownItem onClick={handleDelete} className="py-2">
                      <TrashIcon size="lg" className="mr-2 text-danger" />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>) }
        </tbody>
      </Table>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
