
// outsource dependencies
import _ from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button, Table, Alert, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

// local dependencies
import { usersListCtrl } from './controller';
import { USER_EDIT, USER_STATE } from '../../../constant';
import { humanize, formatFullName } from '../../../services';
import { AlertError, Preloader, SearchInput, SortBy, PlusIcon, Pagination, DotsIcon, EditIcon, TrashIcon, PageSize } from '../../../component';

// configure
const pageSizeOptions = [10, 20, 30];
const dateFormat = 'MMM D, YYYY h:mm A';

export const UserList = memo(function UserList () {
  const [
    { initialized, disabled, errorMessage, list, search, status, size, sortF, sortD, page, totalPages },
    { initialize, updateCtrl, deleteItem, updateFilter }
  ] = useController(usersListCtrl);
  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => _.map(list, item => ({
    ...item,
    name: formatFullName(item),
    onDelete: () => deleteItem(item),
    status: humanize(_.get(item, 'state')),
    gender: humanize(_.get(item, 'gender')),
    createdDate: dayjs(_.get(item, 'createdDate')).format(dateFormat),
  })), [list, deleteItem]);

  const statuses = useMemo(() => _.map(Object.values(USER_STATE), status => ({
    id: status,
    name: humanize(status),
    onClick: () => updateFilter({ page: 0, status })
  })), [updateFilter]);

  // NOTE prepare actions
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);

  return <Container fluid id="UserList" className={cn('user-list', { 'no-events': disabled })}>
    <Preloader active={!initialized} className="app-preloader">
      <Row className="mb-3">
        <Col xs="12">
          <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
          <Row className="d-flex align-items-center mb-3 pb-2 border-bottom">
            <Col xs="2" tag="h2" className="text-primary text-left"> Users </Col>
            <Col xs="3">
              <SearchInput
                value={search}
                onApply={handleApply}
                onClear={handleClear}
                onChange={handleChangeSearch}
              />
            </Col>
            <Col xs="2" className="d-flex justify-content-center">
              <UncontrolledDropdown size="lg">
                <DropdownToggle caret> { humanize(status) } </DropdownToggle>
                <DropdownMenu>
                  {/* eslint-disable-next-line max-len */}
                  { _.map(statuses, ({ id, name, onClick }) => <DropdownItem disabled={id === status} key={id} onClick={onClick}>
                    { name }
                  </DropdownItem>) }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col xs="2" className="d-flex justify-content-center">
              <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
            </Col>
            <Col xs="3" className="text-right">
              <Button to={USER_EDIT.LINK({})} tag={Link} color="primary" className="rounded-pill">
                <PlusIcon className="mr-2" />
                Create user
              </Button>
            </Col>
          </Row>
          <Table bordered striped>
            <thead>
              <tr>
                <th>
                  <SortBy field="firstName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> User Name </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="email" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Email </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="gender" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Gender </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="createdDate" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Created date </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy disabled field="state" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Status </strong>
                  </SortBy>
                </th>
                <th style={{ width: '1%' }}> </th>
              </tr>
            </thead>
            <tbody>
              { isEmpty ? <tr>
                <td colSpan="4">
                  <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
                </td>
              </tr>
                : _.map(prepared, ({ id, name, gender, createdDate, status, email, onDelete }) => <tr key={id}>
                  <td className="text-truncate"> { name } </td>
                  <td className="text-truncate"> { email } </td>
                  <td className="text-nowrap"> { gender } </td>
                  <td className="text-nowrap"> { createdDate } </td>
                  <td className="text-nowrap"> { status } </td>
                  <td className="va-middle p-0">
                    <UncontrolledDropdown>
                      <DropdownToggle color="none" size="lg">
                        <DotsIcon />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag={Link} className="py-2" to={USER_EDIT.LINK({ id })}>
                          <EditIcon size="lg" className="mr-2 text-primary" />
                          Edit
                        </DropdownItem>
                        <DropdownItem onClick={onDelete} className="py-2">
                          <TrashIcon size="lg" className="mr-2 text-danger" />
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>) }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
