
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { updateSyncErrors } from 'redux-form';
import { create } from 'redux-saga-controller';
import { call, put, takeEvery, select, takeLeading } from 'redux-saga/effects';

// local dependencies
import { instanceAPI, ERROR_MESSAGE } from '../../../services';
import { NEW_ID, APP_TITLE, COMPANY_LIST, silence, USER_STATE } from '../../../constant';

// configure
const PAGE_SIZE_USERS = 6;
export const FORM_NAME = 'EDIT_COMPANY';

export const companyEditCtrl = create({
  prefix: 'company-edit',
  actions: {
    loadUsers: 'LOAD_USERS',
    initialize: 'INITIALIZE',
    updateCompany: 'UPDATE_COMPANY',
    inviteMembersOpen: 'INVITE_MEMBERS_OPEN',
    inviteMembersSubmit: 'INVITE_MEMBERS_SUBMIT',
  },
  initial: {
    initialized: false,
    errorMessage: null,
    disabled: false,
    company: null,
    members: {
      list: [],
      search: '',
      selected: [],
      isOpen: false,
    },
  },
  subscriber: function * () {
    yield takeEvery(companyEditCtrl.action.loadUsers.TYPE, silence, loadUsersSaga);
    yield takeEvery(companyEditCtrl.action.initialize.TYPE, silence, initializeSaga);
    yield takeEvery(companyEditCtrl.action.updateCompany.TYPE, silence, updateCompanySaga);
    yield takeEvery(companyEditCtrl.action.inviteMembersOpen.TYPE, silence, inviteMembersOpenSaga);
    yield takeLeading(companyEditCtrl.action.inviteMembersSubmit.TYPE, silence, inviteMembersSubmitSaga);
  }
});

function * initializeSaga ({ payload }) {
  const id = _.get(payload, 'id');

  if (id !== NEW_ID) {
    // TODO sync with BE
    const company = yield call(instanceAPI, { method: 'GET', url: `/companies/profile/${id}` });
    yield put(companyEditCtrl.action.updateCtrl({ company, companyId: _.get(company, 'id') }));
  }
  yield put(companyEditCtrl.action.updateCtrl({ initialized: true, isNew: id === NEW_ID }));
}

function * updateCompanySaga ({ payload }) {
  const { isNew, companyId } = yield select(companyEditCtrl.select);
  try {
    yield call(instanceAPI, {
      data: payload,
      method: isNew ? 'POST' : 'PUT',
      url: `/companies/${isNew ? '' : companyId}`,
    });
    yield call(toastr.success, APP_TITLE, `Company successfully ${isNew ? 'created' : 'updated'}!`);
    yield call(COMPANY_LIST.PUSH);
  } catch ({ message }) {
    if (message === ERROR_MESSAGE.COMPANY_NAME_ALREADY_IN_USE) {
      yield put(updateSyncErrors(FORM_NAME, { name: ERROR_MESSAGE.COMPANY_NAME_ALREADY_IN_USE }));
    }
    yield put(companyEditCtrl.action.updateCtrl({ errorMessage: message }));
  }
}

function * inviteMembersSubmitSaga ({ type, payload }) {
  const { members, companyId } = yield select(companyEditCtrl.select);
  const userIds = _.get(members, 'selected', []);
  const isAdmin = _.get(members, 'isAdmin', false);
  try {
    yield call(instanceAPI, {
      method: 'POST',
      url: '/companies/add-user',
      data: { isAdmin, companyId, userIds }
    });
    yield call(toastr.success, APP_TITLE, `${isAdmin ? 'Admins' : 'Members' } successfully added`);
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
  yield put(companyEditCtrl.action.updateCtrl({ members: { isOpen: false } }));
}

function * inviteMembersOpenSaga ({ type, payload }) {
  const isAdmin = _.get(payload, 'isAdmin', false);

  yield call(loadUsersSaga, { type, payload });
  const { members } = yield select(companyEditCtrl.select);
  yield put(companyEditCtrl.action.updateCtrl({ members: { ...members, isAdmin, isOpen: true } }));
}

function * loadUsersSaga ({ type, payload }) {
  try {
    const { members } = yield select(companyEditCtrl.select);
    yield put(companyEditCtrl.action.updateCtrl({ members: { ...members, ...payload } }));
    const { members: { list: current, search }, companyId } = yield select(companyEditCtrl.select);
    const amount = _.size(current);
    const page = _.get(payload, 'page', Math.round(amount / PAGE_SIZE_USERS));
    const list = page === 0 ? [] : current;
    const { content } = yield call(silence, instanceAPI, {
      method: 'POST',
      url: '/users/filter',
      data: { search, companyId, userState: USER_STATE.ACTIVE },
      params: { page, size: PAGE_SIZE_USERS, sort: ['firstName,DESC'] },
    });

    yield put(companyEditCtrl.action.updateCtrl({ members: {
      ...members,
      list: _.uniqBy(list.concat(content), 'id'),
    } }));
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
}
