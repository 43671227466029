
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import React, { useCallback, memo, useEffect } from 'react';

// local dependencies
import { ReduxFormControl } from './redux-form';

// eslint-disable-next-line max-len
export const RFInputDate = memo(function RFInputDate ({ input, type, meta, label, skipTouch, classNameFormGroup, className, clearOnUnmount, placeholder, ...attr }) {
  const { name, value, onChange, onBlur } = input;
  // NOTE controlled feature to clearing redux form field value on component unmount
  useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  // NOTE handle valid/invalid state and error message for input
  let message = '', statusClass = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
    statusClass += meta.valid ? ' is-valid' : ' is-invalid';
  }
  return <ReduxFormControl
    id={name}
    label={label}
    message={message}
    className={cn('rf-input-date', classNameFormGroup)}
  >
    <DatePicker
      { ...attr }
      id={name}
      selected={value}
      onBlur={handleBlur}
      onChange={onChange}
      placeholderText={placeholder}
      className={cn('rf-input-date-field form-control', statusClass, className)}
    />
  </ReduxFormControl>;
});
RFInputDate.propTypes = {
  ...ReduxFormControl.propTypes,
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  clearOnUnmount: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFInputDate.defaultProps = {
  ...ReduxFormControl.defaultProps,
  className: '',
  skipTouch: false,
  placeholder: null,
  clearOnUnmount: false,
  dateFormat: 'dd/MM/yyyy',
  classNameFormGroup: ReduxFormControl.defaultProps.className,
};
