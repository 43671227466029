
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select, take, takeLeading } from 'redux-saga/effects';

// local dependencies
import { instanceAPI, SweetAlert } from '../../../services';
import { COMPANY_LIST, APP_TITLE, COMPANY_VISIBILITY, COMPANY_DOC_TYPE, COMPANY_STATUS } from '../../../constant';

// configure
export const COMPANY_STATUS_FILTERS = {
  ALL: Object.values(COMPANY_STATUS),
};
_.forEach(Object.values(COMPANY_STATUS), status => COMPANY_STATUS_FILTERS[status] = [status]);

export const companiesListCtrl = create({
  prefix: 'companies-list',
  actions: {
    initialize: 'INITIALIZE',
    deleteItem: 'DELETE_ITEM',
    updateFilter: 'UPDATE_FILTER',
    verifyCompany: 'VERIFY_COMPANY',
    togglePromoteCompany: 'TOGGLE_PROMOTE_COMPANY',
  },
  initial: {
    list: [],
    verify: null,
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filter
    page: 0,
    size: 10,
    search: '',
    sortD: false,
    totalPages: 0,
    sortF: 'memberCount',
    companyStatus: COMPANY_STATUS_FILTERS.ALL,
  },
  subscriber: function * () {
    yield takeEvery(companiesListCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(companiesListCtrl.action.deleteItem.TYPE, deleteItemSaga);
    yield takeEvery(companiesListCtrl.action.updateFilter.TYPE, updateFilterSaga);
    yield takeLeading(companiesListCtrl.action.verifyCompany.TYPE, verifyCompanySaga);
    yield takeLeading(companiesListCtrl.action.togglePromoteCompany.TYPE, togglePromoteCompanySaga);
  }
});

function * initializeSaga () {
  const query = yield call(COMPANY_LIST.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(companiesListCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ payload }) {
  yield put(companiesListCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, companyStatus, size, sortF, sortD, } = yield select(companiesListCtrl.select);
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      url: '/companies/profiles-filter',
      params: { page, size, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] },
      data: {
        search,
        companyStatus,
        visibility: [COMPANY_VISIBILITY.PUBLIC, COMPANY_VISIBILITY.PRIVATE],
      },
    });
    yield put(companiesListCtrl.action.updateCtrl({ list: _.uniqBy(content, 'id'), totalPages, page: pageNumber }));
    const latest = yield select(companiesListCtrl.select);
    yield call(COMPANY_LIST.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(companiesListCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(companiesListCtrl.action.updateCtrl({ disabled: false }));
}

function * togglePromoteCompanySaga ({ type, payload }) {
  const name = _.get(payload, 'name');
  const companyId = _.get(payload, 'id');
  const isPromoted = _.get(payload, 'promoted');

  try {
    const confirmation = yield call(SweetAlert.confirm, {
      title: `Are you sure you want to ${isPromoted ? 'cancel promotion' : 'promote'} company: ${name}?`,
      text: isPromoted ? '' : 'The promotion will be made for an indefinite period. It will be possible to cancel the promotion only manually using the admin application',
      confirmButtonText: isPromoted ? 'Cancel promotion' : 'Yes, promote',
      cancelButtonText: 'Close',
      customClass: {
        confirmButton: isPromoted ? 'bg-danger' : 'bg-success'
      }
    });
    if (!confirmation.value) { return; }

    yield call(instanceAPI, {
      data: {},
      method: 'POST',
      url: isPromoted ? `/companies/${companyId}/promote/cancel` : `/companies/${companyId}/promote`
    });
    yield call(toastr.success, APP_TITLE, `Company ${isPromoted ? 'promotion canceled!' : 'successfully promoted!'}`);
    yield put(companiesListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield put(companiesListCtrl.action.updateCtrl({ errorMessage: message }));
  }
}

function * deleteItemSaga ({ payload }) {
  const id = _.get(payload, 'id');
  const title = _.get(payload, 'name');
  try {
    const confirmation = yield call(SweetAlert.confirm, {
      title: `Are you sure you want to delete company: ${title}?`
    });
    if (!confirmation.value) { return; }

    yield call(instanceAPI, {
      data: {},
      method: 'PUT',
      url: `/companies/${id}/status?status=${COMPANY_STATUS.DELETED}`,
    });
    yield call(toastr.success, APP_TITLE, 'Company successfully deleted!');
    yield put(companiesListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield call(SweetAlert.error, { title: message });
  }
}

function * verifyCompanySaga ({ type, payload }) {
  const id = _.get(payload, 'id');
  try {
    const company = yield call(instanceAPI, { method: 'GET', url: `/companies/profile/${id}` });
    const documents = _.get(company, 'documentDtoList');
    const ownerIds = _.find(documents, { documentType: COMPANY_DOC_TYPE.ID });
    const tradeLicenses = _.find(documents, { documentType: COMPANY_DOC_TYPE.TRADE_LICENSE });
    yield put(companiesListCtrl.action.updateCtrl({ verify: { ownerIds, tradeLicenses, companyId: id } }));

    while (true) {
      const { payload: result } = yield take(companiesListCtrl.action.verifyCompany.TYPE);
      // NOTE cancel/exit
      if (!result) { break; }
      // NOTE verification
      if (_.get(result, 'verify')) {
        yield call(instanceAPI, { method: 'PUT', url: `/companies/${id}/verify`, data: {} });
        yield call(toastr.success, APP_TITLE, 'Company was verified');
        // NOTE rejection
      } else {
        yield call(instanceAPI, { method: 'PUT', url: `/companies/${id}/reject`, data: result });
        yield call(toastr.success, APP_TITLE, 'Company was rejected');
      }
      yield put(companiesListCtrl.action.initialize({}));
      // apply/exit
      break;
    }
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
  yield put(companiesListCtrl.action.updateCtrl({ verify: null }));
}
