
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select, take, takeLeading } from 'redux-saga/effects';

// local dependencies
import { instanceAPI, SweetAlert } from '../../services';
import { PROPERTIES, APP_TITLE, PROPERTY_INT_STATE } from '../../constant';

// configure
const VERIFY_DOCUMENT = {
  ID: 'ID',
  TITLE_DEED: 'TITLE_DEED',
};

export const propertiesListCtrl = create({
  prefix: 'properties-list',
  actions: {
    initialize: 'INITIALIZE',
    deleteItem: 'DELETE_ITEM',
    updateFilter: 'UPDATE_FILTER',
    verifyProperty: 'VERIFY_PROPERTY',
    togglePromoteProperty: 'TOGGLE_PROMOTE_PROPERTY',
    toggleActivateProperty: 'TOGGLE_ACTIVATE_PROPERTY',
  },
  initial: {
    list: [],
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filter
    page: 0,
    size: 10,
    search: '',
    sortD: false,
    totalPages: 0,
    sortF: 'createdDate',
    status: PROPERTY_INT_STATE.ON_VERIFICATION,
  },
  subscriber: function * () {
    yield takeEvery(propertiesListCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(propertiesListCtrl.action.deleteItem.TYPE, deleteItemSaga);
    yield takeLeading(propertiesListCtrl.action.updateFilter.TYPE, updateFilterSaga);
    yield takeLeading(propertiesListCtrl.action.verifyProperty.TYPE, verifyPropertySaga);
    yield takeLeading(propertiesListCtrl.action.togglePromoteProperty.TYPE, togglePromotePropertySaga);
    yield takeLeading(propertiesListCtrl.action.toggleActivateProperty.TYPE, toggleActivatePropertySaga);
  }
});

function * initializeSaga () {
  yield put(propertiesListCtrl.action.clearCtrl());
  const query = yield call(PROPERTIES.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(propertiesListCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ payload }) {
  yield put(propertiesListCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, size, sortF, sortD, status } = yield select(propertiesListCtrl.select);
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      url: '/properties',
      data: { search, propertyIntState: status },
      params: { size, page, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] }
    });
    yield put(propertiesListCtrl.action.updateCtrl({ list: _.uniqBy(content, 'id'), totalPages, page: pageNumber }));
    const latest = yield select(propertiesListCtrl.select);
    yield call(PROPERTIES.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(propertiesListCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(propertiesListCtrl.action.updateCtrl({ disabled: false }));
}

function * togglePromotePropertySaga ({ type, payload }) {
  const title = _.get(payload, 'title');
  const propertyId = _.get(payload, 'id');
  const isPromoted = _.get(payload, 'promoted');

  try {
    const confirmation = yield call(SweetAlert.confirm, {
      title: `Are you sure you want to ${isPromoted ? 'cancel promotion' : 'promote'} property: ${title}?`,
      text: isPromoted ? '' : 'The promotion will be made for an indefinite period. It will be possible to cancel the promotion only manually using the admin application',
      confirmButtonText: isPromoted ? 'Cancel promotion' : 'Yes, promote',
      cancelButtonText: 'Close',
      customClass: {
        confirmButton: isPromoted ? 'bg-danger' : 'bg-success'
      }
    });
    if (!confirmation.value) { return; }

    yield call(instanceAPI, {
      data: {},
      method: 'POST',
      url: isPromoted ? `/properties/${propertyId}/promote/cancel` : `/properties/${propertyId}/promote`
    });
    yield call(toastr.success, APP_TITLE, `Property ${isPromoted ? 'promotion canceled!' : 'successfully promoted!'}`);
    yield put(propertiesListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield put(propertiesListCtrl.action.updateCtrl({ errorMessage: message }));
  }
}

function * deleteItemSaga ({ type, payload }) {
  const id = _.get(payload, 'id');
  const title = _.get(payload, 'title');
  try {
    const confirmation = yield call(SweetAlert.confirm, {
      title: `Are you sure you want to delete property: "${title}"?`
    });
    if (!confirmation.value) { return; }

    yield call(instanceAPI, { method: 'DELETE', url: `/properties/${id}` });
    yield call(toastr.success, APP_TITLE, 'Property successfully deleted!');
    yield put(propertiesListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield call(SweetAlert.error, { title: message });
  }
}

function * toggleActivatePropertySaga ({ type, payload }) {
  const id = _.get(payload, 'id');
  const activate = _.get(payload, 'activate');
  try {
    yield call(instanceAPI, {
      method: 'POST',
      url: `/properties/${id}/change-status`,
      data: { propertyIntState: activate ? PROPERTY_INT_STATE.ACTIVE : PROPERTY_INT_STATE.INACTIVE }
    });
    yield call(toastr.success, APP_TITLE, `Property successfully ${activate ? 'activated' : 'deactivated'}`);
    yield put(propertiesListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
}

function * verifyPropertySaga ({ type, payload }) {
  const propertyId = _.get(payload, 'id');
  try {
    const property = yield call(instanceAPI, { method: 'GET', url: `/properties/${propertyId}` });
    const documents = _.get(property, 'propertyDocuments');
    const IDDocs = _.filter(
      documents, ({ propertyCheckDocumentType }) => propertyCheckDocumentType === VERIFY_DOCUMENT.ID
    );
    const titleDeedDocs = _.filter(
      documents, ({ propertyCheckDocumentType }) => propertyCheckDocumentType === VERIFY_DOCUMENT.TITLE_DEED
    );
    yield put(propertiesListCtrl.action.updateCtrl({ verify: { IDDocs, titleDeedDocs, propertyId } }));

    while (true) {
      const { payload: result } = yield take(propertiesListCtrl.action.verifyProperty.TYPE);
      // NOTE cancel/exit
      if (!result) { break; }
      // NOTE verification
      if (_.get(result, 'verify')) {
        yield call(instanceAPI, {
          method: 'POST',
          url: `/properties/${propertyId}/change-status`,
          data: { propertyIntState: PROPERTY_INT_STATE.ACTIVE }
        });
        yield call(toastr.success, APP_TITLE, 'Property successfully verified!');
        // NOTE rejection
      } else {
        yield call(instanceAPI, {
          method: 'POST',
          url: `/properties/${propertyId}/change-status`,
          data: { ...result, propertyIntState: PROPERTY_INT_STATE.REJECTED }
        });
        yield call(toastr.success, APP_TITLE, 'Property was rejected');
      }
      yield put(propertiesListCtrl.action.initialize({}));
      // apply/exit
      break;
    }
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
  yield put(propertiesListCtrl.action.updateCtrl({ verify: null }));
}
