
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import React, { memo, useRef, useEffect, useCallback } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';

// local dependencies
import { UserMenu } from './user-menu';
import SearchForm from './search-form';
import { WELCOME_SCREEN } from '../../constant';
import { privateLayoutCtrl } from './controller';
import { AppLogo, AppLogoSmall } from '../../images';
import { BarsIcon, SearchIcon } from '../../component';

const Header = memo(function Header () {
  const { expanded } = useControllerData(privateLayoutCtrl);
  const { updateCtrl, toggleAsideMenu } = useControllerActions(privateLayoutCtrl);

  const handleResize = useRef();
  useEffect(() => {
    handleResize.current = _.throttle(() => {
      if (expanded && window.innerWidth < 768) {
        updateCtrl({ expanded: false });
      }
    }, 500, { trailing: true });
  }, [expanded, updateCtrl]);
  useEffect(() => { handleResize.current(); }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize.current);
    return () => window.removeEventListener('resize', handleResize.current);
  }, [updateCtrl]);

  const toggleSearch = useCallback(() => updateCtrl({ showSearch: true }), [updateCtrl]);

  return <header id="header" className="topnavbar-wrapper">
    <nav className="navbar topnavbar">
      <div className="navbar-header">
        <Link className="navbar-brand" to={WELCOME_SCREEN.LINK()}>
          <div className="brand-logo">
            <AppLogo className="app-logo img-fluid mr-2" />
          </div>
          <div className="brand-logo-collapsed">
            <AppLogoSmall className="app-logo-small img-fluid mr-2" />
          </div>
        </Link>
        <Button size="xs" color="link" className={cx({ 'nav-link d-inline-block': expanded }, 'd-none')} onClick={toggleAsideMenu}>
          <BarsIcon size="lg" />
        </Button>
      </div>
      <ul className="navbar-nav flex-row flex-grow-1">
        <li className="nav-item d-flex">
          <Button size="xs" color="link" className={cx({ 'nav-link d-inline-block': !expanded }, 'd-none')} onClick={toggleAsideMenu}>
            <BarsIcon size="lg" />
          </Button>
          <Button size="xs" color="link" className="nav-link" onClick={toggleSearch}>
            <SearchIcon size="lg" />
          </Button>
        </li>
      </ul>
      <ul className="navbar-nav flex-row">
        <SearchForm/>
      </ul>
      <ul className="navbar-nav flex-row">
        <UserMenu />
      </ul>
    </nav>
  </header>;
});

export default Header;
