
// outsource dependencies
import _ from 'lodash';
import dayjs from 'dayjs';
import * as yup from 'yup';
import cn from 'classnames';
import { Field } from 'redux-form';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Table, Alert, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

// local dependencies
import { humanize } from '../../services';
import { importPropertiesCtrl } from './controller';
import { XML_IMPORT_STATUS, createYupSyncValidator, VALID } from '../../constant';
import { AlertError, Preloader, SearchInput, SortBy, Pagination, DotsIcon, PageSize, AppTooltip, FileImportIcon, ReduxForm, RFInput, MinusCircleIcon } from '../../component';

// configure
const pageSizeOptions = [10, 20, 30];
const formValidation = createYupSyncValidator(yup.object().shape({
  reason: VALID.STRING.required('Please enter reject reason.'),
}));

export const ImportProperties = memo(function ImportProperties () {
  const [
    { initialized, disabled, errorMessage, list, search, size, sortF, sortD, page, totalPages, status },
    { initialize, updateCtrl, updateFilter, importXML, rejectXML }
  ] = useController(importPropertiesCtrl);
  const [xmlToReject, setXmlToReject] = useState(null);
  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => list.map(item => {
    const feedUrlShort = _.truncate(_.get(item, 'feedUrl'), { length: 40 });
    return {
      ...item,
      feedUrlShort,
      isAllowedActions: _.get(item, 'status') === XML_IMPORT_STATUS.PENDING,
      createdDate: dayjs(_.get(item, 'createdDate')).format('MMM D, YYYY h:mm A'),
      handleImportXML: () => importXML({ id: _.get(item, 'id') }),
      handleOpenRejectModal: () => setXmlToReject({ ...item, feedUrlShort }),
    };
  }), [list, importXML]);

  const statuses = useMemo(() => _.map(Object.values(XML_IMPORT_STATUS), status => ({
    id: status,
    name: humanize(status),
    onClick: () => updateFilter({ page: 0, status }),
  })), [updateFilter]);
  const statusView = useMemo(() => humanize(status), [status]);

  // NOTE prepare actions
  const handleCloseRejectModal = useCallback(() => setXmlToReject(null), []);
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);
  const handleRejectXml = useCallback(formData => {
    const id = _.get(xmlToReject, 'id');
    rejectXML({ ...formData, id });
    setXmlToReject(null);
  }, [rejectXML, xmlToReject]);

  return <Container fluid id="ImportProperties" className={cn('import-properties', { 'no-events': disabled })}>
    <Preloader active={!initialized} className="app-preloader">
      <Row className="mb-3">
        <Col xs="12">
          <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
          <Row className="d-flex align-items-center mb-3 pb-2 border-bottom">
            <Col xs="4" tag="h2" className="text-primary text-left"> Import Properties </Col>
            <Col xs="3">
              <SearchInput
                value={search}
                onApply={handleApply}
                onClear={handleClear}
                onChange={handleChangeSearch}
              />
            </Col>
            <Col xs="2" className="d-flex justify-content-center">
              <UncontrolledDropdown size="lg">
                <DropdownToggle caret> { statusView } </DropdownToggle>
                <DropdownMenu>
                  { _.map(statuses, ({ id, name, onClick }) => <DropdownItem key={id} onClick={onClick}>
                    { name }
                  </DropdownItem>)}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col xs="2">
              <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
            </Col>
          </Row>
          <Table bordered striped>
            <thead>
              <tr>
                <th>
                  <SortBy field="userName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Name </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="createdDate" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Date </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="phone" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Mobile phone </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="email" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Email </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="companyName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> Company </strong>
                  </SortBy>
                </th>
                <th>
                  <SortBy field="feedUrl" sortF={sortF} sortD={sortD} onChange={handleSort}>
                    <strong className="text-primary"> XML Source </strong>
                  </SortBy>
                </th>
                <th style={{ width: '1%' }}> </th>
              </tr>
            </thead>
            <tbody>
              { isEmpty ? <tr>
                <td colSpan="6">
                  <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
                </td>
              </tr>
                : _.map(prepared, ({ id, userName, phone, companyName, email, feedUrl, feedUrlShort,
                  createdDate, isAllowedActions, handleImportXML, handleOpenRejectModal
                }) => <tr key={id}>
                  <td className="text-truncate"> { userName } </td>
                  <td className="text-truncate"> { createdDate } </td>
                  <td className="text-nowrap"> { phone } </td>
                  <td className="text-nowrap"> { email } </td>
                  <td className="text-nowrap"> { companyName } </td>
                  <AppTooltip tag="td" content={feedUrl} className="text-truncate">
                    <a href={feedUrl} target="_blank" rel="noopener noreferrer"> { feedUrlShort } </a>
                  </AppTooltip>
                  <td className="va-middle p-0">
                    { isAllowedActions && <UncontrolledDropdown>
                      <DropdownToggle color="none" size="lg">
                        <DotsIcon />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem className="py-2" onClick={handleImportXML}>
                          <FileImportIcon size="lg" className="mr-2 text-primary" />
                          Import XML
                        </DropdownItem>
                        <DropdownItem className="py-2" onClick={handleOpenRejectModal}>
                          <MinusCircleIcon size="lg" className="mr-2 text-warning" />
                          Reject XML
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> }
                  </td>
                </tr>) }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
      <Modal id="XML_REJECT_MODAL" isOpen={Boolean(xmlToReject)}>
        <Button close onClick={handleCloseRejectModal} className="position-absolute p-3" style={{ right: 0 }} />
        <ModalHeader> Reject XML </ModalHeader>
        <ReduxForm
          form="REJECT_REASON"
          validate={formValidation}
          onSubmit={handleRejectXml}
        >
          <ModalBody>
            <h5 className="mb-3"> Are you sure you want to reject XML? </h5>
            <Field
              type="text"
              name="reason"
              component={RFInput}
              placeholder="Reject reason..."
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseRejectModal} type="button" color="primary" className="rounded-pill mr-2"> Cancel </Button>
            <Button type="submit" color="danger" className="rounded-pill"> Reject </Button>
          </ModalFooter>
        </ReduxForm>
      </Modal>
    </Preloader>
  </Container>;
});
