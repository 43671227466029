
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Tooltip } from 'reactstrap';

// local dependencies
import { useRefCallback, useToggle } from '../hook';

// eslint-disable-next-line max-len
export const AppTooltip = memo(function AppTooltip ({ children, content, className, popperClassName, placement, tag: Tag, ...attr }) {
  const [isVisible, toggleVisible] = useToggle();
  const [target, ref] = useRefCallback();

  return <Tag
    ref={ref}
    { ...attr }
    onMouseOut={toggleVisible}
    onMouseDown={toggleVisible}
    onMouseEnter={toggleVisible}
    className={cn('app-tooltip', className)}
  >
    { target && <Tooltip
      target={target}
      isOpen={isVisible}
      placement={placement}
      toggle={toggleVisible}
      popperClassName={cn('app-tooltip-popper', popperClassName)}
    >
      { content }
    </Tooltip> }
    { children }
  </Tag>;
});
AppTooltip.propTypes = {
  content: PropTypes.node,
  tag: PropTypes.elementType,
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  placement: Tooltip.propTypes.placement,
  children: Tooltip.propTypes.children.isRequired,
};
AppTooltip.defaultProps = {
  tag: 'div',
  className: null,
  placement: 'top',
  content: 'Tooltip',
  popperClassName: null,
};
