
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// local dependencies
import * as appIcons from './app-svg-icons';

/**
 * As main Icon component we will use the font-awesome really awesome component
 * @example
 * <Icon icon={['far', icon]} {...attr} />
 */
export const FaIcon = FontAwesomeIcon;
/****************************************************
 *    prepared icons
 ****************************************************/

// NOTE add app icons to FA library
library.add({ ...appIcons });

export const FavHeartFillIcon = memo(function FavHeartFillIcon (props) {
  return <FontAwesomeIcon icon={['app', 'fav-heart-fill']} {...props} />;
});

export const FavHeartOutlineIcon = memo(function FavHeartOutlineIcon (props) {
  return <FontAwesomeIcon icon={['app', 'fav-heart-outline']} {...props} />;
});

export const EnvelopIcon = memo(function EnvelopIcon (props) {
  return <FontAwesomeIcon icon={['app', 'envelop']} {...props} />;
});

export const EditIcon = memo(function EditIcon (props) {
  return <FontAwesomeIcon icon={['app', 'edit']} {...props} />;
});

export const TrashIcon = memo(function TrashIcon (props) {
  return <FontAwesomeIcon icon={['app', 'trash']} {...props} />;
});

export const SendIcon = memo(function SendIcon (props) {
  return <FontAwesomeIcon icon={['app', 'send']} {...props} />;
});

export const ArrowDropdownCircleIcon = memo(function ArrowDropdownCircleIcon (props) {
  return <FontAwesomeIcon icon={['app', 'arrow-dropdown-circle']} {...props} />;
});

export const GroupsIcon = memo(function GroupsIcon (props) {
  return <FontAwesomeIcon icon={['app', 'groups']} {...props} />;
});

export const PeopleIcon = memo(function PeopleIcon (props) {
  return <FontAwesomeIcon icon={['app', 'people']} {...props} />;
});

export const HomeIcon = memo(function HomeIcon (props) {
  return <FontAwesomeIcon icon={['app', 'home']} {...props} />;
});

export const CompanyIcon = memo(function CompanyIcon (props) {
  return <FontAwesomeIcon icon={['app', 'company']} {...props} />;
});

export const StarIcon = memo(function StarIcon (props) {
  return <FontAwesomeIcon icon={['app', 'star']} {...props} />;
});

export const CommentIcon = memo(function CommentIcon (props) {
  return <FontAwesomeIcon icon={['app', 'comment']} {...props}/>;
});

export const VideoCameraIcon = memo(function VideoCameraIcon (props) {
  return <FontAwesomeIcon icon={['app', 'video-camera']} {...props}/>;
});

export const GoogleIcon = memo(function GoogleIcon (props) {
  return <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props} >
    <path d="M23.04 12.2615C23.04 11.446 22.9668 10.6619 22.8309 9.90918H12V14.3576H18.1891C17.9225 15.7951 17.1123 17.013 15.8943 17.8285V20.714H19.6109C21.7855 18.7119 23.04 15.7637 23.04 12.2615Z" fill="#4285F4"/>
    <path d="M11.9995 23.5001C15.1045 23.5001 17.7077 22.4703 19.6104 20.7139L15.8938 17.8285C14.864 18.5185 13.5467 18.9262 11.9995 18.9262C9.00425 18.9262 6.46902 16.9032 5.5647 14.1851H1.72266V17.1646C3.61493 20.923 7.50402 23.5001 11.9995 23.5001Z" fill="#34A853"/>
    <path d="M5.56523 14.185C5.33523 13.495 5.20455 12.7579 5.20455 12C5.20455 11.242 5.33523 10.505 5.56523 9.81499V6.83545H1.72318C0.944318 8.38795 0.5 10.1443 0.5 12C0.5 13.8557 0.944318 15.612 1.72318 17.1645L5.56523 14.185Z" fill="#FBBC05"/>
    <path d="M11.9995 5.07386C13.6879 5.07386 15.2038 5.65409 16.3956 6.79364L19.694 3.49523C17.7024 1.63955 15.0992 0.5 11.9995 0.5C7.50402 0.5 3.61493 3.07705 1.72266 6.83545L5.5647 9.815C6.46902 7.09682 9.00425 5.07386 11.9995 5.07386Z" fill="#EA4335"/>
  </svg>;
});

library.add(fas.faEyeSlash);
export const EyeSlashIcon = memo(function EyeSlashIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'eye-slash']} {...props} />;
});

library.add(fas.faPlus);
export const PlusIcon = memo(function PlusIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'plus']} {...props} />;
});

library.add(fas.faCheckCircle);
export const CheckCircleIcon = memo(function CheckCircleIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'check-circle']} {...props} />;
});

library.add(fas.faShoppingCart);
export const ShoppingCartIcon = memo(function ShoppingCartIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'shopping-cart']} {...props} />;
});

library.add(fas.faAd);
export const AdIcon = memo(function AdIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'ad']} {...props} />;
});

library.add(fas.faChartLine);
export const ChartLineIcon = memo(function AdChartLine (props) {
  return <FontAwesomeIcon icon={['fas', 'chart-line']} {...props} />;
});

library.add(fas.faMinusCircle);
export const MinusCircleIcon = memo(function MinusCircleIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'minus-circle']} {...props} />;
});

library.add(fas.faEye);
export const EyeIcon = memo(function EyeIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'eye']} {...props} />;
});

library.add(fas.faCog);
export const CogIcon = memo(function CogIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'cog']} {...props} />;
});

library.add(fas.faUserCog);
export const UerCogIcon = memo(function UerCogIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'user-cog']} {...props} />;
});

library.add(fas.faSignOutAlt);
export const SignOutIcon = memo(function SignOutIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'sign-out-alt']} {...props} />;
});

library.add(fas.faTimes);
export const TimesIcon = memo(function TimesIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'times']} {...props} />;
});

library.add(fas.faBars);
export const BarsIcon = memo(function BarsIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'bars']} {...props} />;
});

library.add(fas.faSearch);
export const SearchIcon = memo(function SearchIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'search']} {...props} />;
});

library.add(fas.faSort);
export const SortIcon = memo(function SortIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'sort']} {...props} />;
});

library.add(fas.faEllipsisH);
export const DotsIcon = memo(function DotsIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'ellipsis-h']} {...props} />;
});

library.add(fas.faSortAmountUp);
export const SortAmountUpIcon = memo(function SortAmountUpIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'sort-amount-up']} {...props} />;
});

library.add(fas.faSortAmountDown);
export const SortAmountDownIcon = memo(function SortAmountDownIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'sort-amount-down']} {...props} />;
});

library.add(fas.faFileImport);
export const FileImportIcon = memo(function FileImportIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'file-import']} {...props} />;
});

library.add(fas.faExclamationTriangle);
export const ExclamationTriangle = memo(function ExclamationTriangle (props) {
  return <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} {...props} />;
});

library.add(fas.faPenSquare);
export const PenSquareIcon = memo(function PenSquareIcon (props) {
  return <FontAwesomeIcon icon={['fas', 'pen-square']} {...props} />;
});

/********************************************************************************************************/
export const SorDirection = memo(function SortingIcon ({ status, classMap, statusMap, className, ...attr }) {
  const Icon = useMemo(() => statusMap(status), [statusMap, status]);
  return <Icon { ...attr } className={cn('sort-direction', classMap(status, attr), className)} />;
});
SorDirection.propTypes = {
  status: PropTypes.any,
  classMap: PropTypes.func,
  statusMap: PropTypes.func,
  className: PropTypes.string,
};
SorDirection.defaultProps = {
  status: null,
  className: '',
  statusMap: status => {
    switch (status) {
      default: return SortIcon;
      case true: return SortAmountUpIcon;
      case false: return SortAmountDownIcon;
    }
  },
  classMap: (status, { disabled }) => {
    switch (status) {
      default: return cn('ml-1 mr-1 text-thin', disabled ? 'text-muted' : 'text-gray');
      case true: return cn('ml-1 mr-1 text-bold', disabled ? 'text-muted' : 'text-gray-d');
      case false: return cn('ml-1 mr-1 text-bold', disabled ? 'text-muted' : 'text-gray-d');
    }
  }
};

export const FavHeartToggle = memo(function FavHeartToggle ({ status, statusMap, className, ...attr }) {
  const Icon = useMemo(() => statusMap(status), [statusMap, status]);
  return <Icon { ...attr } className={cn('', className)} />;
});

FavHeartToggle.propTypes = {
  status: PropTypes.any,
  statusMap: PropTypes.func,
  className: PropTypes.string,
};
FavHeartToggle.defaultProps = {
  status: null,
  className: '',
  statusMap: status => {
    switch (status) {
      default: return FavHeartOutlineIcon;
      case true: return FavHeartFillIcon;
      case false: return FavHeartOutlineIcon;
    }
  }
};
