
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import React, { memo, useEffect } from 'react';
import { useControllerData } from 'redux-saga-controller';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';

// local dependencies
import { Avatar } from '../images';
// import { SignUp } from './sign-up';
import { SignIn } from './sign-in';
import { config } from '../constant';
import { appRootCtrl } from '../controller';
import * as ROUTES from '../constant/route';
import { ForgotPassword } from './forgot-password';
import { ChangePassword } from './change-password';
import { EmailConfirmation } from './email-confirmation';
import { usePath, useSelfName, useSelfAvatar } from '../hook';

import { NoMatch } from '../no-match';

export const PublicLayout = memo(function PublicLayout () {
  const location = useLocation();
  const { user } = useControllerData(appRootCtrl);
  // NOTE show message to suggest proceed as detected user
  useEffect(() => {
    user && toastr.message('Authorization detected', { closeOnToastrClick: true, component: UserProceed });
    // NOTE remove toastr automatically if we go out from public layout
    return () => toastr.removeByType('message');
  }, [user]);

  return <Switch>
    <Route exact path={ROUTES.SIGN_IN.ROUTE} component={SignIn} />
    {/* NOTE wait for BE implementation */}
    {/* <Route exact path={ROUTES.SIGN_UP.ROUTE} component={SignUp} /> */}
    <Route exact path={ROUTES.FORGOT_PASSWORD.ROUTE} component={ForgotPassword} />
    <Route path={ROUTES.CHANGE_PASSWORD.ROUTE} component={ChangePassword} />
    <Route path={ROUTES.EMAIL_CONFIRMATION.ROUTE} component={EmailConfirmation} />
    {/* OTHERWISE */}
    { config('DEBUG')
      ? <Route component={NoMatch} />
      : <Redirect to={{ pathname: ROUTES.SIGN_IN.LINK(), state: { from: location } }}/>
    }
  </Switch>;
});

const UserProceed = memo(() => {
  const avatar = useSelfAvatar();
  const selfName = useSelfName();
  const goHome = usePath(ROUTES.WELCOME_SCREEN.LINK());

  return <Card className="m-n2">
    <Avatar
      src={avatar}
      alt={selfName}
      style={{ width: 100, height: 100 }}
      className="card-img rounded-circle mt-3 ml-3 mb-0"
    />
    <CardBody>
      <CardTitle> Hi { selfName } </CardTitle>
      <CardText>
        You are already logged in.
        If you will login by another user, the current logged user will be logged out automatically.
        Probably you want to proceed ?
      </CardText>
      <Button
        color="primary"
        onClick={goHome}
        className="btn-block rounded-pill text-nowrap"
      >
        Proceed as &ldquo;{ selfName }&ldquo;
      </Button>
    </CardBody>
  </Card>;
});
