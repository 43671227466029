
// outsource dependencies
import ReduxToastr from 'react-redux-toastr';
import React, { memo, useEffect } from 'react';
import { useController } from 'redux-saga-controller';
import { Route, Switch, Redirect, Router } from 'react-router-dom';

// local dependencies
import { appRootCtrl } from './controller';
import { PublicLayout } from './public-layout';
import { PrivateLayout } from './private-layout';
import { Preloader, Maintenance } from './component';
import { LAYOUT_PUBLIC, LAYOUT_PRIVATE, SIGN_IN, config, history } from './constant';

// TODO remove ?
import { NoMatch } from './no-match';


export const App = memo(function App () {
  // NOTE subscribe app controller
  const [{ initialized, health }, { initialize }] = useController(appRootCtrl);
  // NOTE initialize business logic
  useEffect(() => { initialize(); }, [initialize]);
  // NOTE select view based on application state
  if (!health) { return <Maintenance />; }
  if (!initialized) { return <Preloader active className="app-preloader" />; }
  return <>
    <Router history={history}>
      <Switch>
        <Route path={LAYOUT_PUBLIC} component={PublicLayout} />
        <Route path={LAYOUT_PRIVATE} component={PrivateLayout} />
        { config('DEBUG')
          // NOTE otherwise only for debug
          ? <Route component={NoMatch} />
          : <Redirect to={{ pathname: SIGN_IN.LINK(), state: { from: history.location } }}/>
        }
      </Switch>
    </Router>
    <ReduxToastr
      progressBar
      timeOut={2000}
      preventDuplicates
      newestOnTop={false}
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
  </>;
});
