
// outsource dependencies
import * as yup from 'yup';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Container, Row, Button, Col } from 'reactstrap';
import React, { memo, useCallback, useEffect } from 'react';

// local dependencies
import { AppLogo } from '../../images';
import { forgotPasswordCtrl } from './controller';
import { createYupSyncValidator, SIGN_IN, VALID } from '../../constant';
import { AlertError, Spinner, ReduxForm, RFInput, RFCaptcha } from '../../component';

// configure
const FORM_NAME = 'forgotPasswordForm';
const formValidation = createYupSyncValidator(yup.object().shape({
  email: VALID.EMAIL.required('Email is required'),
}));

export const ForgotPassword = memo(function ForgotPassword () {
  const [
    { disabled, errorMessage },
    { initialize, updateData, updateCtrl },
  ] = useController(forgotPasswordCtrl);
    // NOTE initialize business logic
  useEffect(() => { initialize(); }, [initialize]);
  // NOTE prepare page actions
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);

  return <Container fluid id="ForgotPassword" className="forgot-password">
    <ReduxForm
      form={FORM_NAME}
      onSubmit={updateData}
      validate={formValidation}
    >
      <Row className="align-items-center">
        <Col xs="12" className="text-center mb-3">
          <AppLogo className="app-logo" />
        </Col>
        <Col xs="12" tag="h2" className="text-center text-info">
            Forgot password ?
        </Col>
        <Col xs="12" tag="h5" className="text-center text-muted mb-4">
            Please enter your email address, and we&apos;ll send you a password reset email.
        </Col>
        <Col xs="12">
          <Field
            type="text"
            name="email"
            component={RFInput}
            disabled={disabled}
            placeholder="Email Address"
          />
          <Field name="captcha" disabled={disabled} component={RFCaptcha} />
          <Button
            block
            outline
            type="submit"
            color="primary"
            disabled={disabled}
            className="rounded-pill mb-3"
          >
              Reset Password
            <Spinner active={disabled} />
          </Button>
          <AlertError active message={errorMessage} onClear={handleClearError}/>
        </Col>
        <Col xs="6" className="text-center mb-3">
          <Link to={SIGN_IN.LINK()}>
            <strong> Sign In </strong>
          </Link>
        </Col>
        {/* <Col xs="6" className="text-center mb-3">
          <Link to={SIGN_UP.LINK()}>
            <strong> Sign Up </strong>
          </Link>
        </Col> */}
      </Row>
    </ReduxForm>
  </Container>;
});
