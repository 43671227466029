
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import React, { memo, useCallback, useEffect } from 'react';

// local dependencies
import { ReduxFormControl } from './redux-form';

// eslint-disable-next-line max-len
export const RFRadio = memo(function RFRadio ({ input, meta, label, options, skipTouch, classNameFormGroup, getOptionKey, className, getOptionValue, getOptionLabel, clearOnUnmount, ...attr }) {
  const { name, value, onBlur, onChange } = input;
  // NOTE controlled feature to clearing redux form field value on component unmount
  useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);
  // NOTE handle valid/invalid state and error message for input
  let message = '', statusClass = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
    statusClass += meta.valid ? ' is-valid' : ' is-invalid';
  }
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);

  return <ReduxFormControl
    id={name}
    label={label}
    message={message}
    className={cn('rf-radio', statusClass, classNameFormGroup)}
  >
    { _.map(options, item => <CustomInput
      { ...attr }
      type="radio"
      name={name}
      selected={value}
      onBlur={handleBlur}
      key={getOptionKey(item)}
      value={getOptionValue(item)}
      label={getOptionLabel(item)}
      id={`radio-${name}-${getOptionKey(item)}`}
      onChange={() => onChange(getOptionValue(item))}
      checked={_.isEqual(getOptionValue(item), value)}
      className={cn('rf-radio-field', className)}
    />) }
  </ReduxFormControl>;
});
RFRadio.propTypes = {
  ...ReduxFormControl.propTypes,
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionKey: PropTypes.func,
  getOptionValue: PropTypes.func,
  clearOnUnmount: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFRadio.defaultProps = {
  ...ReduxFormControl.defaultProps,
  className: '',
  skipTouch: false,
  clearOnUnmount: false,
  getOptionLabel: item => _.get(item, 'label', item),
  getOptionValue: item => _.get(item, 'value', item),
  classNameFormGroup: ReduxFormControl.defaultProps.className,
  getOptionKey: item => _.get(item, 'value', _.uniqueId('radio-option-')),
};
