
import { cookieStorage, Storage } from './storage';

/**
 * Prepared stores for specific things
 */
const local = new Storage(window.localStorage);
const session = new Storage(window.sessionStorage);
const cookie = new Storage(cookieStorage);
// NOTE primitive checking of support - for example "Incognito Mode"
const storage = local.isSupported ? local : session.isSupported ? session : cookie;

/**
 * Ready to use stores for specific things
 */
export const AccessTokenStorage = storage.bindToPath('ats');
export const RefreshTokeStorage = storage.bindToPath('rts');
