
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { updateSyncErrors } from 'redux-form';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { signIn } from '../../services';
import { appRootCtrl } from '../../controller';
import { silence, APP_TITLE, WELCOME_SCREEN } from '../../constant';

// configure
export const FORM_NAME = 'SIGN_IN';
export const signInCtrl = create({
  prefix: 'sign-in',
  actions: {
    initialize: 'INITIALIZE',
    signIn: 'SIGN_IN',
  },
  initial: {
    disabled: false,
    initialValues: {},
    initialized: false,
    errorMessage: null,
  },
  subscriber: function * () {
    yield takeEvery(signInCtrl.action.initialize.TYPE, silence, initializeSaga);
    yield takeEvery(signInCtrl.action.signIn.TYPE, silence, signInSaga);
  }
});

function * initializeSaga ({ type, payload }) {
  // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n payload:', payload
  // );
  // NOTE do nothing
  yield put(signInCtrl.action.updateCtrl({ initialized: true }));
}

function * signInSaga ({ type, payload }) {
  // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n payload:', payload
  // );
  yield put(signInCtrl.action.updateCtrl({ disabled: true, errorMessage: null }));
  try {
    const self = yield call(signIn, payload);
    yield put(appRootCtrl.action.updateCtrl({ user: self }));
    yield call(WELCOME_SCREEN.PUSH, {});
    yield call(toastr.success, `Welcome to ${APP_TITLE}`, 'We pleasure to see you');
  } catch ({ message }) {
    yield call(toastr.error, 'Error', message);
    yield put(signInCtrl.action.updateCtrl({ errorMessage: message }));
    yield put(updateSyncErrors(FORM_NAME, { password: ' ', email: ' ' }));
  }
  yield put(signInCtrl.action.updateCtrl({ disabled: false }));
}
