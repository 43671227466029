
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import React, { memo, useEffect } from 'react';

// local dependencies
import { ReduxFormControl } from './redux-form';

// eslint-disable-next-line max-len
export const RFCheckbox = memo(function RFCheckbox ({ input, meta, skipTouch, className, classNameFormGroup, clearOnUnmount, ...attr }) {
  const { name, value, onChange } = input;
  // NOTE controlled feature to clearing redux form field value on component unmount
  useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);
  // NOTE handle valid/invalid state and error message for input
  let message = '', statusClass = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
    statusClass += meta.valid ? ' is-valid' : ' is-invalid';
  }

  return <ReduxFormControl
    id={name}
    message={message}
    className={cn('rf-checkbox', classNameFormGroup)}
  >
    <CustomInput
      { ...attr }
      id={name}
      name={name}
      value={value}
      type="checkbox"
      onChange={onChange}
      checked={Boolean(value)}
      className={cn('rf-checkbox-field', statusClass, className)}
    />
  </ReduxFormControl>;
});
RFCheckbox.propTypes = {
  skipTouch: PropTypes.bool,
  clearOnUnmount: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: ReduxFormControl.propTypes.label,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFCheckbox.defaultProps = {
  label: null,
  skipTouch: false,
  clearOnUnmount: false,
  classNameFormGroup: '',
};
