
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Table, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

// local dependencies
import { Verify } from './verify';
import { humanize } from '../../../services';
import { COMPANY_STATUS, COMPANY_EDIT } from '../../../constant';
import { companiesListCtrl, COMPANY_STATUS_FILTERS } from './controller';
import { Preloader, AlertError, TrashIcon, SearchInput, SortBy, DotsIcon, Pagination, PageSize, SendIcon, EditIcon, CheckCircleIcon, MinusCircleIcon, AdIcon } from '../../../component';

// configure
const pageSizeOptions = [10, 20, 30];

export const CompaniesList = memo(function CompaniesList () {
  const [
    { initialized, errorMessage, disabled, list, sortD, sortF, size, page, search, companyStatus, totalPages },
    { initialize, updateCtrl, updateFilter, deleteItem, verifyCompany, togglePromoteCompany }
  ] = useController(companiesListCtrl);
  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => _.map(list, item => {
    const isPromoted = _.get(item, 'promoted');
    const status = _.get(item, 'companyStatus');
    const isVerified = status === COMPANY_STATUS.VERIFIED;
    return {
      ...item,
      isVerified,
      isPromoted,
      onDelete: () => deleteItem(item),
      onVerify: () => verifyCompany(item),
      onTogglePromote: () => togglePromoteCompany(item),
      companyStatus: humanize(status),
      country: _.get(item, 'country.name'),
      isAllowPromote: isVerified && !isPromoted,
      category: _.get(item, 'businessArea.name'),
      isDeleted: status === COMPANY_STATUS.DELETED,
      isAllowCancelPromote: isVerified && isPromoted,
      // NOTE business requirement @link {@see https://estative.atlassian.net/browse/EST-825}
      isAllowVerify: [COMPANY_STATUS.PENDING, COMPANY_STATUS.CREATED].includes(status),
    };
  }), [list, deleteItem, verifyCompany, togglePromoteCompany]);

  const statuses = useMemo(() => _.map(Object.entries(COMPANY_STATUS_FILTERS), ([key, value]) => ({
    id: key,
    name: humanize(key),
    onClick: () => updateFilter({ page: 0, companyStatus: value })
  })), [updateFilter]);
  const companyStatusView = useMemo(() => _.size(companyStatus) > 1 ? 'All' : humanize(companyStatus), [companyStatus]);

  // NOTE prepare actions
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);

  return <Container fluid id="CompaniesList" className={cn('companies-list', { 'no-events': disabled })}>
    <Verify />
    <Preloader active={!initialized} className="app-preloader">
      <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
      <Row className="d-flex align-items-center mb-3 pb-2 border-bottom">
        <Col xs="3" tag="h2" className="text-primary text-left"> Companies </Col>
        <Col xs="3">
          <SearchInput
            value={search}
            onApply={handleApply}
            onClear={handleClear}
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="2" className="d-flex justify-content-center">
          <UncontrolledDropdown size="lg">
            <DropdownToggle caret> { companyStatusView } </DropdownToggle>
            <DropdownMenu>
              { _.map(statuses, ({ id, name, onClick }) => <DropdownItem key={id} onClick={onClick}>
                { name }
              </DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col xs="2" className="d-flex justify-content-center">
          <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
        </Col>
        <Col xs="2" className="text-right">
          <Button color="primary" className="rounded-pill text-nowrap" tag={Link} to={COMPANY_EDIT.LINK({})}>
            Create company
          </Button>
        </Col>
      </Row>
      <Table bordered striped>
        <thead>
          <tr>
            <th>
              <SortBy field="name" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Company </strong>
              </SortBy>
            </th>
            <th>
              <SortBy disabled field="" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Category </strong>
              </SortBy>
            </th>
            <td>
              <SortBy disabled field="" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Country </strong>
              </SortBy>
            </td>
            <th>
              <SortBy field="email" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Email </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="companyStatus" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Status </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="promoted" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Promoted </strong>
              </SortBy>
            </th>
            <th style={{ width: '1%' }}> </th>
          </tr>
        </thead>
        <tbody>
          { isEmpty ? <tr>
            <td colSpan="6">
              <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
            </td>
          </tr> : _.map(prepared, ({
            id, name, category, country, email, isDeleted, companyStatus, onDelete, onVerify, isAllowVerify,
            isPromoted, isAllowPromote, isAllowCancelPromote, onTogglePromote
          }) => <tr key={id}>
            <td className="text-truncate"> { name } </td>
            <td className="text-truncate"> { category } </td>
            <td className="text-truncate"> { country } </td>
            <td className="text-truncate"> { email } </td>
            <td className="text-truncate"> { companyStatus } </td>
            <td className="text-center">
              { isPromoted ? <CheckCircleIcon size="2x" className="text-success" />
                : <MinusCircleIcon className="text-danger" size="2x" /> }
            </td>
            <td className="va-middle p-0">
              <UncontrolledDropdown className="d-flex justify-content-center">
                <DropdownToggle color="none" size="lg">
                  <DotsIcon />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag={Link} className="py-2" to={COMPANY_EDIT.LINK({ id })}>
                    <EditIcon size="lg" className="mr-2 text-primary" />
                    Edit
                  </DropdownItem>
                  { isAllowVerify && <DropdownItem onClick={onVerify} className="py-2">
                    <SendIcon size="lg" className="mr-2 text-primary fa-rotate-270" />
                    Verify
                  </DropdownItem> }
                  { isAllowPromote && <DropdownItem onClick={onTogglePromote} className="py-2">
                    <AdIcon size="lg" className="mr-2 text-primary" />
                    Promote
                  </DropdownItem> }
                  { isAllowCancelPromote && <DropdownItem onClick={onTogglePromote} className="py-2">
                    <AdIcon size="lg" className="mr-2 text-danger" />
                    Cancel Promote
                  </DropdownItem> }
                  { !isDeleted && <DropdownItem onClick={onDelete} className="py-2">
                    <TrashIcon size="lg" className="mr-2 text-danger" />
                    Delete
                  </DropdownItem> }
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>) }
        </tbody>
      </Table>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
