
// outsource dependencies
import React, { memo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// local dependencies
import { CompanyEdit } from './edit';
import { CompaniesList } from './list';
import * as ROUTE from '../../constant/route';

export const Companies = memo(function Companies () {
  const location = useLocation();

  return <Switch>
    <Route path={ROUTE.COMPANY_LIST.ROUTE} component={CompaniesList} />
    <Route path={ROUTE.COMPANY_EDIT.ROUTE} component={CompanyEdit} />

    <Redirect to={{ pathname: ROUTE.COMPANY_LIST.LINK(), state: { from: location } }} />
  </Switch>;
});
