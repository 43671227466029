
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from '../../services';
import { VIDEO_REQUESTS, VIDEO_REQUEST_STATUS, silence, APP_TITLE } from '../../constant';

export const videoRequestsCtrl = create({
  prefix: 'video-requests',
  actions: {
    initialize: 'INITIALIZE',
    updateFilter: 'UPDATE_FILTER',
    closeRequest: 'CLOSE_REQUEST',
  },
  initial: {
    list: [],
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filter
    page: 0,
    size: 10,
    search: '',
    sortD: true,
    totalPages: 0,
    sortF: 'createdDate',
    status: VIDEO_REQUEST_STATUS.PENDING,
  },
  subscriber: function * () {
    yield takeEvery(videoRequestsCtrl.action.initialize.TYPE, silence, initializeSaga);
    yield takeEvery(videoRequestsCtrl.action.updateFilter.TYPE, silence, updateFilterSaga);
    yield takeEvery(videoRequestsCtrl.action.closeRequest.TYPE, silence, closeRequestSaga);
  }
});

function * initializeSaga ({ type, payload }) {
  const query = yield call(VIDEO_REQUESTS.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(videoRequestsCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ type, payload }) {
  yield put(videoRequestsCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, size, sortF, sortD, status } = yield select(videoRequestsCtrl.select);
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      data: { search, status },
      url: '/extra-service/video/filter',
      params: { page, size, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] },
    });
    yield put(videoRequestsCtrl.action.updateCtrl({ list: _.uniqBy(content, 'cartId'), totalPages, page: pageNumber }));
    const latest = yield select(videoRequestsCtrl.select);
    yield call(VIDEO_REQUESTS.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(videoRequestsCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(videoRequestsCtrl.action.updateCtrl({ disabled: false }));
}

function * closeRequestSaga ({ type, payload }) {
  const id = _.get(payload, 'id');
  const closingMessage = _.get(payload, 'closingMessage');
  if (!id) { return null; }
  try {
    yield call(instanceAPI, {
      method: 'POST',
      url: `/extra-service/video/${id}/status`,
      data: { status: VIDEO_REQUEST_STATUS.CLOSED, closingMessage }
    });
    yield call(toastr.success, APP_TITLE, 'Video Request closed');
    yield put(videoRequestsCtrl.action.initialize({}));
  } catch ({ message }) {
    yield call(toastr.error, APP_TITLE, message);
  }
}
