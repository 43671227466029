
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Table, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// local dependencies
import { truncate } from '../../services';
import { postsListCtrl } from './controller';
import { Preloader, AlertError, TrashIcon, SearchInput, SortBy, DotsIcon, Pagination, PageSize } from '../../component';

// configure
const pageSizeOptions = [10, 20, 30];

export const PostsList = memo(function PostsList () {
  const [
    { initialized, errorMessage, disabled, list, sortD, sortF, size, page, search, totalPages },
    { initialize, updateCtrl, updateFilter, deleteItem }
  ] = useController(postsListCtrl);
  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => _.map(list, item => ({
    ...item,
    link: _.get(item, 'link'),
    onDelete: () => deleteItem(item),
    description: truncate(_.get(item, 'description'), { length: 100 }),
  })), [list, deleteItem]);

  // NOTE prepare actions
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);

  return <Container fluid id="PostsList" className={cn('posts-list', { 'no-events': disabled })} >
    <Preloader active={!initialized} className="app-preloader">
      <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
      <Row className="mb-3 border-bottom">
        <Col xs="3" tag="h2" className="text-primary text-left"> Posts </Col>
        <Col xs="5">
          <SearchInput
            value={search}
            onApply={handleApply}
            onClear={handleClear}
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="2">
          <PageSize options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
        </Col>
      </Row>
      <Table bordered striped>
        <thead>
          <tr>
            <th style={{ width: '48%' }}>
              <SortBy field="description" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Post description </strong>
              </SortBy>
            </th>
            <th style={{ width: '48%' }}>
              <SortBy disabled field="link" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Post link </strong>
              </SortBy>
            </th>
            <th tyle={{ width: '1%' }}> </th>
          </tr>
        </thead>
        <tbody>
          { isEmpty ? <tr>
            <td colSpan="3">
              <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
            </td>
          </tr>
            : _.map(prepared, ({ id, onDelete, description, link }) => <tr key={id}>
              <td className="text-truncate"> { description } </td>
              <td className="text-truncate"> { link } </td>
              <td className="va-middle p-0">
                <UncontrolledDropdown className="d-flex justify-content-center">
                  <DropdownToggle size="lg" color="none">
                    <DotsIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={onDelete} className="py-2">
                      <TrashIcon size="lg" className="mr-2 text-danger" />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>) }
        </tbody>
      </Table>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
