
// outsource dependencies
import _ from 'lodash';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';

// local dependencies
import { instanceAPI } from './services';
import { appRootCtrl } from './controller';
import { COMPANY_VISIBILITY, history } from './constant';

export const useSelf = () => _.get(useControllerData(appRootCtrl), 'user');
export const useSelfId = () => _.get(useControllerData(appRootCtrl), 'user.id');
export const useSelfAvatar = () => _.get(useControllerData(appRootCtrl), 'user.profilePictureUri');
export const useSelfName = () => {
  const self = useSelf();
  return useMemo(() => {
    const firstName = _.get(self, 'firstName');
    const lastName = _.get(self, 'lastName');
    return firstName ? `${firstName} ${lastName || ''}` : null;
  }, [self]);
};

/**
 * correct extract ref to provide ability use ref with "useEffect" hook
 */
export const useRefCallback = () => {
  const [stored, set] = useState(null);
  // NOTE prevent update "reference" within render
  const ref = useCallback(api => api && set(api), []);
  return [stored, ref];
};

/**
 * simple prepared boolean and toggle fn
 */
export const useToggle = initial => {
  const [value, set] = useState(Boolean(initial));
  return [value, useCallback(() => set(current => !current), [])];
};


/**
 * common list of countries
 */
export const useCountries = () => {
  const { getCountries } = useControllerActions(appRootCtrl);
  useEffect(() => { getCountries({}); }, [getCountries]);
  return useControllerData(appRootCtrl).countries;
};

/**
 * common mechanism to get cities by country
 * @see { @link https://dmitripavlutin.com/react-throttle-debounce/ | debounced/throttle functions }
 */
export const useCitiesByCountry = options => {
  const [cities, setCities] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const countryId = _.get(options, 'id');
  const cityId = _.get(options, 'cityId');
  const handleGetCity = useMemo(() => _.debounce(name => {
    if (!countryId) { return; }
    setLoading(true);
    instanceAPI({
      url: '/city',
      method: 'POST',
      params: { page: 0, size: 512 },
      data: { countryId, name, includeIds: cityId ? [cityId] : void(0) },
    })
      .then(success => setCities(success.content))
      .catch(() => setCities([]))
      .finally(() => setLoading(false));
  }, 3e2), [cityId, countryId]);
  useEffect(() => () => handleGetCity && handleGetCity.cancel(), [handleGetCity]);

  useEffect(() => {
    if (!countryId) { return; }
    handleGetCity('');
  }, [countryId, handleGetCity]);
  return [cities, handleGetCity, isLoading];
};

/**
 * common list of business areas
 */
export const useBusinessAreas = () => {
  const { getBusinessAreas } = useControllerActions(appRootCtrl);
  useEffect(() => { getBusinessAreas({}); }, [getBusinessAreas]);
  return useControllerData(appRootCtrl).businessAreas;
};

/**
 * common list of companies
 */
export const useAllCompanies = options => {
  const [companies, setCompanies] = useState([]);
  const companyId = _.get(options, 'id');
  const handleGetCompanies = useMemo(() => _.debounce(search => {
    instanceAPI({
      method: 'POST',
      params: { page: 0, size: 12 },
      url: '/companies/profiles-filter',
      data: {
        search,
        // companyStatus: COMPANY_STATUS.VERIFIED,
        includeIds: companyId ? [companyId] : void(0),
        visibility: [COMPANY_VISIBILITY.PUBLIC, COMPANY_VISIBILITY.PRIVATE],
      },
    })
      .then(success => setCompanies(_.get(success, 'content') || []))
      .catch(() => setCompanies([]));
  }, 3e2), [companyId]);
  // NOTE clear on unmount
  useEffect(() => () => handleGetCompanies && handleGetCompanies.cancel(), [handleGetCompanies]);
  // NOTE handle changing companyId
  useEffect(() => { companyId && handleGetCompanies(''); }, [companyId, handleGetCompanies]);

  return [companies, handleGetCompanies];
};

/**
 * common way to navigate
 */
export const usePath = path => useCallback(() => history.push(path), [path]);
