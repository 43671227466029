
// outsource dependencies
import React, { memo } from 'react';
import { Container, Row, Col } from 'reactstrap';

// local dependencies
import { AppLogo } from '../../images';
import { APP_TITLE } from '../../constant';

export const Welcome = memo(function Welcome () {
  return <Container fluid>
    <h4 className="pt-3 mb-0 text-center"> Welcome to { APP_TITLE }! </h4>
    <hr className="row" />
    <Row>
      <Col xs="12" className="text-center">
        <AppLogo className="img-fluid" style={{ width: 300 }} />
      </Col>
    </Row>
  </Container>;
});
