
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Tooltip } from 'reactstrap';

// local dependencies
import { ExclamationTriangle } from './icon';
import { useRefCallback, useToggle } from '../hook';

export const Hint = memo(function Hint ({ children, className, popperClassName, placement }) {
  const [isVisible, toggleVisible] = useToggle();
  const [target, ref] = useRefCallback();

  return <div
    ref={ref}
    onMouseOut={toggleVisible}
    onMouseDown={toggleVisible}
    onMouseEnter={toggleVisible}
    className={cn('hint', className)}
  >
    <ExclamationTriangle size="sm" className="hint-icon" />
    { target && <Tooltip
      target={target}
      isOpen={isVisible}
      placement={placement}
      toggle={toggleVisible}
      popperClassName={cn('hint-popper', popperClassName)}
    >
      { children }
    </Tooltip> }
  </div>;
});
Hint.propTypes = {
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  placement: Tooltip.propTypes.placement,
  children: Tooltip.propTypes.children.isRequired,
};
Hint.defaultProps = {
  className: null,
  placement: 'top',
  popperClassName: null,
};
/**
'auto-start'
'auto'
'auto-end'
'top-start'
'top'
'top-end'
'right-start'
'right'
'right-end'
'bottom-end'
'bottom'
'bottom-start'
'left-end'
'left'
'left-start'
 */
