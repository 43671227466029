
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { call, put, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI, humanize } from '../../../services';
import { NEW_ID, USER_LIST, APP_TITLE } from '../../../constant';


export const userEditCtrl = create({
  prefix: 'user-edit',
  actions: {
    initialize: 'INITIALIZE',
    updateUser: 'UPDATE_USER',
  },
  initial: {
    initialized: false,
    errorMessage: null,
    disabled: false,
    user: {},
  },
  subscriber: function * () {
    yield takeEvery(userEditCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(userEditCtrl.action.updateUser.TYPE, updateUserSaga);
  }
});

function * initializeSaga ({ payload }) {
  const id = _.get(payload, 'id');

  if (id !== NEW_ID) {
    const user = yield call(instanceAPI, { method: 'GET', url: `/users/${id}` });
    yield put(userEditCtrl.action.updateCtrl({
      userId: _.get(user, 'id'),
      initial: {
        cityId: _.get(user, 'city.id'),
        firstName: _.get(user, 'firstName'),
        lastName: _.get(user, 'lastName'),
        countryId: _.get(user, 'country.id'),
        emailAddress: _.get(user, 'emailAddress'),
        roles: _.get(user, 'roles', []).map(role => ({ value: role.name, label: humanize(role.name) })),
      } }));
  }

  yield put(userEditCtrl.action.updateCtrl({ initialized: true, isNew: id === NEW_ID }));
}

function * updateUserSaga ({ payload }) {
  const { isNew, userId } = yield select(userEditCtrl.select);
  try {
    yield call(instanceAPI, {
      method: isNew ? 'POST' : 'PUT',
      url: `/users/${isNew ? '' : userId}`,
      data: {
        ...payload,
        roles: _.get(payload, 'roles', []).map(role => role.value)
      },
    });
    yield call(toastr.success, APP_TITLE, `User successfully ${isNew ? 'created' : 'updated'}!`);
    yield call(USER_LIST.PUSH);
  } catch ({ message }) {
    yield put(userEditCtrl.action.updateCtrl({ errorMessage: message }));
  }
}
