
// outsource dependencies
import _ from 'lodash';
import dayjs from 'dayjs';
import * as yup from 'yup';
import cn from 'classnames';
import { Field } from 'redux-form';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Table, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Button, Badge, ModalFooter } from 'reactstrap';

// local dependencies
import { humanize } from '../../services';
import { videoRequestsCtrl } from './controller';
import { VIDEO_REQUEST_STATUS, createYupSyncValidator, VALID } from '../../constant';
import { Preloader, AlertError, SearchInput, SortBy, DotsIcon, Pagination, PageSize, ReduxForm, RFInput } from '../../component';

// configure
const pageSizeOptions = [10, 20, 30];
const VIDEO_TYPE = {
  INTERVIEW: 'Interview',
  PROPERTY_SHOW: 'Property Show',
  COMPANY_SHOW: 'Company Profile (Product or Show)',
};
const formValidation = createYupSyncValidator(yup.object().shape({
  closingMessage: VALID.STRING.required('Please enter close message'),
}));

export const VideoRequests = memo(function VideoRequests () {
  const [
    { initialized, errorMessage, disabled, list, sortD, sortF, size, page, search, totalPages, status },
    { initialize, updateCtrl, updateFilter, closeRequest }
  ] = useController(videoRequestsCtrl);
  const [details, setDetails] = useState(null);
  const [requestId, setRequestId] = useState(null);

  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const isDetailsOpen = Boolean(details);
  const prepared = useMemo(() => _.map(list, item => {
    const id = _.get(item, 'id');
    return {
      ...item,
      city: _.get(item, 'city.name'),
      country: _.get(item, 'country.name'),
      company: _.get(item, 'company.name'),
      status: humanize(_.get(item, 'status')),
      service: _.get(item, 'extraService.name'),
      date: dayjs(_.get(item, 'createdDate')).format('MMM D, YYYY h:mm A'),
      isPending: _.get(item, 'status') === VIDEO_REQUEST_STATUS.PENDING,
      closeRequest: () => setRequestId(id),
      showDetails: () => setDetails(_.find(list, { id })),
    };
  }), [list]);

  const {
    name, email, phone, city, country, message, company, designation, service, date, interview,
    propertyShow, companyShow, requestStatus, updatedDate, closingMessage
  } = useMemo(() => ({
    name: _.get(details, 'name'),
    email: _.get(details, 'email'),
    phone: _.get(details, 'phone'),
    city: _.get(details, 'city.name'),
    message: _.get(details, 'message'),
    country: _.get(details, 'country.name'),
    company: _.get(details, 'company.name'),
    designation: _.get(details, 'designation'),
    service: _.get(details, 'extraService.name'),
    closingMessage: _.get(details, 'closingMessage'),
    requestStatus: humanize(_.get(details, 'status')),
    date: dayjs(_.get(details, 'createdDate')).format('MMM D, YYYY h:mm A'),
    updatedDate: dayjs(_.get(details, 'updatedDate')).format('MMM D, YYYY h:mm A'),
    interview: _.get(details, 'isInterview') && VIDEO_TYPE.INTERVIEW,
    companyShow: _.get(details, 'isCompanyShow') && VIDEO_TYPE.COMPANY_SHOW,
    propertyShow: _.get(details, 'isPropertyShow') && VIDEO_TYPE.PROPERTY_SHOW,
  }), [details]);

  const statuses = useMemo(() => Object.values(VIDEO_REQUEST_STATUS).map(item => ({
    id: item,
    name: humanize(item),
    onClick: () => updateFilter({ page: 0, status: item }),
  })), [updateFilter]);

  // NOTE prepare actions
  const handleCloseDetailsModal = useCallback(() => setDetails(null), []);
  const handleCloseRequestModal = useCallback(() => setRequestId(null), []);
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);
  const handleSubmitCloseRequest = useCallback(formData => {
    closeRequest({ ...formData, id: requestId });
    setRequestId(null);
  }, [closeRequest, requestId]);

  return <Container fluid id="VideoRequests" className={cn('video-requests', { 'no-events': disabled })}>
    <Modal className="close-request-modal" isOpen={Boolean(requestId)} toggle={handleCloseRequestModal}>
      <Button close onClick={handleCloseRequestModal} className="position-absolute p-3" style={{ right: 0 }} />
      <ModalHeader> Close Video Request </ModalHeader>
      <ReduxForm onSubmit={handleSubmitCloseRequest} form="CLOSE_MESSAGE" validate={formValidation}>
        <ModalBody>
          <Field type="text" name="closingMessage" component={RFInput} placeholder="Message..." />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" className="rounded-pill">
            Close
          </Button>
        </ModalFooter>
      </ReduxForm>
    </Modal>
    <Modal className="details-modal" isOpen={isDetailsOpen} toggle={handleCloseDetailsModal}>
      <Button close onClick={handleCloseDetailsModal} className="position-absolute p-3" style={{ right: 0 }} />
      <ModalHeader> Request details </ModalHeader>
      <ModalBody className="border-bottom">
        <Row className="d-flex align-items-center mb-2">
          <Col xs="6">
            Status: <Badge color="primary"> { requestStatus } </Badge>
          </Col>
          { updatedDate && <Col xs="6">
            <small> Updated: { updatedDate } </small>
          </Col> }
        </Row>
        { closingMessage && <Row className="mb-2">
          <Col xs="12">
            { closingMessage }
          </Col>
        </Row> }
      </ModalBody>
      <ModalBody>
        <Row>
          <Col tag="h4" xs="12">
            { name }
          </Col>
        </Row>
        { company && <Row className="mb-2">
          <Col xs="6">
            <span className="text-primary"> { designation } </span> at <b> { company } </b>
          </Col>
        </Row> }
        <Row className="mb-2">
          <Col xs="12"> <b> { country }, { city } </b> </Col>
        </Row>
        <Row className="mb-2">
          <Col className="mb-0" tag="p" xs="6"> { phone } </Col>
          <Col className="mb-0" tag="p" xs="6"> { email } </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="6"> <h5 className="text-primary font-weight-bold"> { service } </h5> </Col>
          <Col xs="6"> <b> { date } </b> </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="12">
            { interview && <Badge className="mr-2" color="success"> { interview } </Badge> }
            { companyShow && <Badge className="mr-2" color="success"> { companyShow } </Badge> }
            { propertyShow && <Badge color="success"> { propertyShow } </Badge> }
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <p> { message } </p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
    <Preloader active={!initialized} className="app-preloader">
      <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
      <Row className="d-flex align-items-center mb-3 pb-2 border-bottom">
        <Col xs="3" tag="h2" className="text-primary text-left"> Video Requests </Col>
        <Col xs="3">
          <SearchInput
            value={search}
            onApply={handleApply}
            onClear={handleClear}
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="3" className="d-flex justify-content-center">
          <UncontrolledDropdown size="lg">
            <DropdownToggle caret> { humanize(status) } </DropdownToggle>
            <DropdownMenu>
              {/* eslint-disable-next-line max-len */}
              { _.map(statuses, ({ id, name, onClick }) => <DropdownItem disabled={id === status} key={id} onClick={onClick}>
                { name }
              </DropdownItem>) }
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col xs="2" className="d-flex justify-content-center">
          <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
        </Col>
      </Row>
      <Table bordered striped>
        <thead>
          <tr>
            <th>
              <SortBy field="name" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Name </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="company" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Company </strong>
              </SortBy>
            </th>
            <td>
              <SortBy field="country" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Location </strong>
              </SortBy>
            </td>
            <th>
              <SortBy field="extraService" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Service </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="createdDate" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Date </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="status" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Status </strong>
              </SortBy>
            </th>
            <th style={{ width: '1%' }}> </th>
          </tr>
        </thead>
        <tbody>
          { isEmpty ? <tr>
            <td colSpan="6">
              <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
            </td>
          </tr> : _.map(prepared, ({
            cartId, name, city, country, service, date, status, company, isPending, showDetails, closeRequest
          }) => <tr key={cartId}>
            <td className="text-truncate"> { name } </td>
            <td className="text-truncate"> { company } </td>
            <td className="text-truncate"> { country }, { city } </td>
            <td className="text-truncate"> { service } </td>
            <td className="text-truncate"> { date } </td>
            <td className="text-truncate"> { status } </td>
            <td className="va-middle p-0">
              <UncontrolledDropdown className="d-flex justify-content-center">
                <DropdownToggle color="none" size="lg">
                  <DotsIcon />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={showDetails} className="py-2">
                    Show Details
                  </DropdownItem>
                  { isPending && <DropdownItem onClick={closeRequest} className="text-danger py-2">
                    Close Request
                  </DropdownItem> }
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>) }
        </tbody>
      </Table>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
