
// outsource dependencies
import _ from 'lodash';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from '../../services';
import { CART_TYPE, PAYMENT_STATUS, PAYMENTS } from '../../constant';

// configure
export const paymentsListCtrl = create({
  prefix: 'payments-list',
  actions: {
    initialize: 'INITIALIZE',
    updateFilter: 'UPDATE_FILTER',
  },
  initial: {
    list: [],
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filter
    page: 0,
    size: 10,
    search: '',
    sortD: true,
    totalPages: 0,
    sortF: 'updatedDate',
    status: PAYMENT_STATUS.PAID,
    cartType: CART_TYPE.COMPANY_VALIDATE,
  },
  subscriber: function * () {
    yield takeEvery(paymentsListCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(paymentsListCtrl.action.updateFilter.TYPE, updateFilterSaga);
  }
});

function * initializeSaga () {
  yield put(paymentsListCtrl.action.clearCtrl());
  const query = yield call(PAYMENTS.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(paymentsListCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ payload }) {
  yield put(paymentsListCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, size, sortF, sortD, cartType, status } = yield select(paymentsListCtrl.select);
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      url: '/payments/filter',
      data: { search, cartType, status },
      params: { size, page, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] }
    });
    yield put(paymentsListCtrl.action.updateCtrl({ list: _.uniqBy(content, 'id'), totalPages, page: pageNumber }));
    const latest = yield select(paymentsListCtrl.select);
    yield call(PAYMENTS.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(paymentsListCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(paymentsListCtrl.action.updateCtrl({ disabled: false }));
}
