
// outsource dependencies
import React, { memo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// local dependencies
import { UserEdit } from './edit';
import { UserList } from './list';
import * as ROUTE from '../../constant/route';

export const Users = memo(function Users () {
  const location = useLocation();

  return <Switch>
    <Route path={ROUTE.USER_LIST.ROUTE} component={UserList} />
    <Route path={ROUTE.USER_EDIT.ROUTE} component={UserEdit} />

    <Redirect to={{ pathname: ROUTE.USER_LIST.LINK(), state: { from: location } }} />
  </Switch>;
});
