
// outsource dependencies
import _ from 'lodash';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { silence } from '../../constant';
import { appRootCtrl } from '../../controller';
import { instanceAPI, setupSession, getSelf } from '../../services';

// configure
export const emailConfirmCtrl = create({
  prefix: 'email-confirmation',
  actions: {
    initialize: 'INITIALIZE',
  },
  initial: {
    disabled: false,
    initialized: false,
    errorMessage: null,
    isTokenValid: false,
  },
  subscriber: function * () {
    yield takeEvery(emailConfirmCtrl.action.initialize.TYPE, silence, initializeSaga);
  }
});

function * initializeSaga ({ type, payload }) {
  try {
    const activationToken = _.get(payload, 'token');
    const session = yield call(instanceAPI, {
      method: 'POST',
      url: '/users/activate',
      data: { activationToken }
    });
    // NOTE token is fine
    yield call(setupSession, session);
    const self = yield call(getSelf);
    yield put(appRootCtrl.action.updateCtrl({ user: self }));
    yield put(emailConfirmCtrl.action.updateCtrl({ success: true }));
  } catch ({ message: errorMessage }) {
    yield put(emailConfirmCtrl.action.updateCtrl({ errorMessage }));
  }
  yield put(emailConfirmCtrl.action.updateCtrl({ initialized: true }));
}
