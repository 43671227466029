
// outsource dependencies
import _ from 'lodash';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { Field } from 'redux-form';
import React, { memo, useCallback } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from 'reactstrap';

// local dependencies
import { companiesListCtrl } from './controller';
import { appRootCtrl } from '../../../controller';
import { ReduxForm, RFInput } from '../../../component';
import { VALID, createYupSyncValidator } from '../../../constant';

// configure
const formValidation = createYupSyncValidator(yup.object().shape({
  message: VALID.STRING.required('Reject reason is mandatory if you want to reject company verification'),
}));

export const Verify = memo(function Verify () {
  const { download } = useControllerActions(appRootCtrl);
  const { verify } = useControllerData(companiesListCtrl);
  const { verifyCompany } = useControllerActions(companiesListCtrl);

  const isOpen = Boolean(verify);
  const idSources = _.get(verify, 'ownerIds.sources');
  const licenseSources = _.get(verify, 'tradeLicenses.sources');
  const expireDate = dayjs(_.get(verify, 'tradeLicenses.expireDate')).format('MMMM D, YYYY');

  // NOTE prepare actions
  const handleClose = useCallback(() => verifyCompany(null), [verifyCompany]);
  const handleVerify = useCallback(() => verifyCompany({ verify: true }), [verifyCompany]);

  return <Modal id="Verify" isOpen={isOpen} toggle={handleClose}>
    <Button close onClick={handleClose} className="position-absolute p-3" style={{ right: 0 }} />
    <ModalHeader> Verify company </ModalHeader>
    <ModalBody>
      <Row>
        <Col tag="h5"> User Passport or ID </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3">
          { _.map(idSources, ({ id, name }) => <Button
            id={id}
            size="sm"
            color="none"
            className="text-primary text-nowrap mr-3"
            onClick={() => download({ url: `/companies/document/${id}`, fileName: name })}
          >
            download { name }
          </Button>)}
        </Col>
      </Row>
      <Row>
        <Col tag="h5"> Trade License </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3">
          { _.map(licenseSources, ({ id, name }) => <Button
            id={id}
            size="sm"
            color="none"
            className="text-primary text-nowrap mr-3"
            onClick={() => download({ url: `/companies/document/${id}`, fileName: name })}
          >
            download { name }
          </Button>)}
        </Col>
      </Row>
      <Row>
        <Col xs="12" tag="p" className="mb-5">
          Expiry date for Trade License <strong> { expireDate } </strong>
        </Col>
      </Row>
      <ReduxForm onSubmit={verifyCompany} form="REJECT_REASON" validate={formValidation}>
        <Field type="text" name="message" component={RFInput} placeholder="Reject reason..." />
        <Button outline type="submit" color="danger" className="rounded-pill">
          Reject
        </Button>
      </ReduxForm>
    </ModalBody>
    <ModalFooter>
      <Button type="button" color="primary" onClick={handleVerify} className="rounded-pill">
        Verify
      </Button>
    </ModalFooter>
  </Modal>;
});
