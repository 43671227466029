
// outsource dependencies

// local dependencies
import _ from 'lodash';
import { defineRoute, ANNOTATION, NEW_ID } from '../services/route';
import { USER_STATE, COMPANY_STATUS, CART_TYPE, PAYMENT_STATUS, VIDEO_REQUEST_STATUS, PROPERTY_INT_STATE, XML_IMPORT_STATUS } from './variables';

export { NEW_ID };
export const LAYOUT_PUBLIC = '/public';
export const LAYOUT_PRIVATE = '/private';

/*-------------------------------------------------
        LAYOUT_PUBLIC nested routes
---------------------------------------------------*/
export const SIGN_IN = defineRoute(`${LAYOUT_PUBLIC}/sign-in`);
export const SIGN_UP = defineRoute(`${LAYOUT_PUBLIC}/sign-up`);
export const FORGOT_PASSWORD = defineRoute(`${LAYOUT_PUBLIC}/forgot-password`);
export const CHANGE_PASSWORD = defineRoute(`${LAYOUT_PUBLIC}/change-password/:token`, {
  params: [ANNOTATION.TOKEN({})]
});
export const EMAIL_CONFIRMATION = defineRoute(`${LAYOUT_PUBLIC}/email-confirmation/:token`, {
  params: [ANNOTATION.TOKEN({})]
});

/*-------------------------------------------------
        LAYOUT_PRIVATE nested routes
---------------------------------------------------*/
export const WELCOME_SCREEN = defineRoute(`${LAYOUT_PRIVATE}/welcome`);

export const USERS = defineRoute(`${LAYOUT_PRIVATE}/users`);
export const USER_LIST = defineRoute(`${USERS.ROUTE}/list`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: true }),
    ANNOTATION.SORT_F({ defaults: 'createdDate', isValid: v => ['createdDate'].indexOf(v) > -1 }),
    { short: 'stts', name: 'status', defaults: USER_STATE.ACTIVE, isValid: v => Object.values(USER_STATE).includes(v) },
  ]
});
export const USER_EDIT = defineRoute(`${USERS.ROUTE}/edit/:id`, {
  params: [ANNOTATION.ID()],
});

export const PROPERTIES = defineRoute(`${LAYOUT_PRIVATE}/properties`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: false }),
    ANNOTATION.SORT_F({ defaults: 'createdDate', isValid: v => ['createdDate'].indexOf(v) > -1 }),
    { short: 'stts', name: 'status', defaults: PROPERTY_INT_STATE.ON_VERIFICATION, isValid: v => _.includes(Object.values(PROPERTY_INT_STATE), v) },
  ]
});

export const IMPORT_PROPERTIES = defineRoute(`${LAYOUT_PRIVATE}/import-properties`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: true }),
    ANNOTATION.SORT_F({ defaults: 'createdDate', isValid: v => ['createdDate'].indexOf(v) > -1 }),
    { short: 'stts', name: 'status', defaults: XML_IMPORT_STATUS.PENDING, isValid: v => Object.values(XML_IMPORT_STATUS).includes(v) }
  ]
});

export const COMPANIES = defineRoute(`${LAYOUT_PRIVATE}/companies`);
export const COMPANY_LIST = defineRoute(`${COMPANIES.ROUTE}/list`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: true }),
    ANNOTATION.SORT_F({ defaults: 'memberCount', isValid: v => ['memberCount'].indexOf(v) > -1 }),
    { short: 'stts', name: 'companyStatus', defaults: Object.values(COMPANY_STATUS), isValid: v => _.isArray(v) },
  ]
});
export const COMPANY_EDIT = defineRoute(`${COMPANIES.ROUTE}/edit/:id`, {
  params: [ANNOTATION.ID()],
});

export const GROUPS = defineRoute(`${LAYOUT_PRIVATE}/groups`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: false }),
    ANNOTATION.SORT_F({ defaults: 'memberCount', isValid: v => ['memberCount'].indexOf(v) > -1 }),
  ]
});

export const POSTS = defineRoute(`${LAYOUT_PRIVATE}/posts`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: false }),
    ANNOTATION.SORT_F({ defaults: 'description', isValid: v => ['description'].indexOf(v) > -1 }),
  ]
});

export const PAYMENTS = defineRoute(`${LAYOUT_PRIVATE}/payments`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: true }),
    ANNOTATION.SORT_F({ defaults: 'updatedDate', isValid: v => ['updatedDate'].indexOf(v) > -1 }),
    { short: 'stts', name: 'status', defaults: PAYMENT_STATUS.PAID, isValid: v => _.includes(Object.values(PAYMENT_STATUS), v) },
    { short: 'crtTp', name: 'cartType', defaults: CART_TYPE.COMPANY_VALIDATE, isValid: v => _.includes(Object.values(CART_TYPE), v) }
  ]
});

export const COMMENTS = defineRoute(`${LAYOUT_PRIVATE}/comments`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: false }),
    ANNOTATION.SORT_F({ defaults: 'comment', isValid: v => ['comment'].indexOf(v) > -1 }),
  ]
});

export const VIDEO_REQUESTS = defineRoute(`${LAYOUT_PRIVATE}/video-requests`, {
  query: [
    ANNOTATION.PAGE({ defaults: 0 }),
    ANNOTATION.SIZE({ defaults: 10 }),
    ANNOTATION.SEARCH({ defaults: '' }),
    ANNOTATION.SORT_D({ defaults: true }),
    ANNOTATION.SORT_F({ defaults: 'createdDate', isValid: v => ['createdDate'].indexOf(v) > -1 }),
    { short: 'stts', name: 'status', defaults: VIDEO_REQUEST_STATUS.PENDING, isValid: v => _.includes(Object.values(VIDEO_REQUEST_STATUS), v) },
  ]
});
