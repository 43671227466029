
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';

// local dependencies
import { RFSelect } from './index';
import { useCountries, useCitiesByCountry } from '../hook';

// configure
const getValue = item => _.get(item, 'id', null);
const getLabel = item => _.get(item, 'name', null);
const useFormValues = formName => useSelector(getFormValues(formName)) || {};
const useClearSelected = (formName, field) => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(change(formName, field, null, false)), [dispatch, formName, field]);
};
// eslint-disable-next-line max-len
export const RFLocation = memo(function RFLocation ({ form, countryField, cityField, isSimple, disabled, className, clearOnUnmount, skipTouch, ...attr }) {
  const countries = useCountries();
  const isCountryLoading = !_.size(countries);
  const formValues = useFormValues(form);
  const country = _.get(formValues, countryField);
  const countryId = isSimple ? country : _.get(country, 'id');
  const clearSelectedCity = useClearSelected(form, cityField);
  const [cities, getCities, isCityLoading] = useCitiesByCountry({ id: countryId });

  const handleCitiesInputChange = useCallback((text, action) => {
    if (_.get(action, 'action') !== 'input-change') { return; }
    getCities(text);
  }, [getCities]);

  return <div className={cn('rf-location', className)} { ...attr }>
    <Field
      isSimple={isSimple}
      name={countryField}
      options={countries}
      disabled={disabled}
      component={RFSelect}
      skipTouch={skipTouch}
      // NOTE clear selected city in case country changed
      onChange={clearSelectedCity}
      // NOTE take in mind the Chrome browser will enable auto complete based on placeholder prop
      placeholder="Country"
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      isLoading={isCountryLoading}
      clearOnUnmount={clearOnUnmount}
    />
    <Field
      name={cityField}
      options={cities}
      placeholder="City"
      isSimple={isSimple}
      component={RFSelect}
      skipTouch={skipTouch}
      isLoading={isCityLoading}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      clearOnUnmount={clearOnUnmount}
      disabled={disabled || !countryId}
      onInputChange={handleCitiesInputChange}
    />
  </div>;
});
RFLocation.propTypes = {
  isSimple: PropTypes.bool,
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  clearOnUnmount: PropTypes.bool,
  form: PropTypes.string.isRequired,
  cityField: PropTypes.string.isRequired,
  countryField: PropTypes.string.isRequired,
};
RFLocation.defaultProps = {
  className: '',
  isSimple: false,
  skipTouch: false,
  clearOnUnmount: false,
};
