
// outsource dependencies
import React, { memo } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// local dependencies
import { Users } from './users';
import { Layout } from './layout';
import { useSelf } from '../hook';
import { Welcome } from './welcome';
import { config } from '../constant';
import { PostsList } from './posts';
import { GroupsList } from './groups';
import { Companies } from './companies';
import { PaymentsList } from './payments';
import { CommentsList } from './comments';
import { PropertiesList } from './properties';
import { VideoRequests } from './video-requests';
import { ImportProperties } from './import-properties';

import * as ROUTES from '../constant/route';

import { NoMatch } from '../no-match';

export const PrivateLayout = memo(function PrivateLayout () {
  const user = useSelf();
  const location = useLocation();

  return !user
    ? <Redirect to={{ pathname: ROUTES.SIGN_IN.ROUTE, state: { from: location } }}/>
    : <Layout>
      <Switch>
        <Route path={ROUTES.IMPORT_PROPERTIES.ROUTE} component={ImportProperties} />
        <Route path={ROUTES.VIDEO_REQUESTS.ROUTE} component={VideoRequests} />
        <Route path={ROUTES.PROPERTIES.ROUTE} component={PropertiesList} />
        <Route path={ROUTES.WELCOME_SCREEN.ROUTE} component={Welcome} />
        <Route path={ROUTES.PAYMENTS.ROUTE} component={PaymentsList} />
        <Route path={ROUTES.COMMENTS.ROUTE} component={CommentsList} />
        <Route path={ROUTES.COMPANIES.ROUTE} component={Companies} />
        <Route path={ROUTES.GROUPS.ROUTE} component={GroupsList} />
        <Route path={ROUTES.POSTS.ROUTE} component={PostsList} />
        <Route path={ROUTES.USERS.ROUTE} component={Users} />
        {/*OTHERWISE*/}
        { config('DEBUG')
          ? <Route component={NoMatch} />
          : <Redirect to={{ pathname: ROUTES.WELCOME_SCREEN.LINK(), state: { from: location } }}/>
        }
      </Switch>
    </Layout>;
});
