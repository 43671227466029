
export const APP_TITLE = 'Estative';
export const noop = () => void 0;

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN', // Full control, create admins
  ADMIN: 'ADMIN',             // Full control with access to delete entities like : user, post, property, company, group
  USER: 'USER',               // Simple user does not have access to admin app
  EDITOR: 'EDITOR',           // Create user, block/unblock users, verify company/property but could not remove any record
  MODERATOR: 'MODERATOR',     // Could not create any record but can delete property, post, comment, message, group, company
};

export const DIR = {
  FLOOR_PLAN: 'FLOOR_PLAN', // properties flor plan pics
  PROPERTY: 'PROPERTY',     // properties pics
  DOCUMENT: 'DOCUMENT',     // wtf ???
  DEFAULT: 'DEFAULT',       // wtf ???
  COMPANY: 'COMPANY',       // company pics
  GROUP: 'GROUP',           // group pics
  ALBUM: 'ALBUM',           // album pics
  POST: 'POST',             // post pics
  USER: 'USER',             // user pics
};

export const CURRENCY = {
  USD: 'USD',               // US Dollar
  EUR: 'EUR',               // Euro
  GBP: 'GBP',               // Pound Sterling
  AED: 'AED',               // UAE Dirham
  EGP: 'EGP',               // Egyptian Pound
  SAR: 'SAR',               // Saudi Riyal
};

export const PROPERTY_INT_STATE = {
  PENDING: 'PENDING',                   // property in creation process
  CREATED: 'CREATED',                   // property was created (only for property owners)
  ON_VERIFICATION: 'ON_VERIFICATION',   // property waiting for verification
  ACTIVE: 'ACTIVE',                     // property was verified
  REJECTED: 'REJECTED',                 // property was rejected
  INACTIVE: 'INACTIVE',                 // property was deleted from the system
};

export const PROPERTY_RS_TYPE = {
  RENT: 'RENT',
  SALE: 'SALE',
};

export const COMPANY_POLICY = {
  EVERYONE: 'EVERYONE',
  INVITE: 'INVITE',
};

export const COMPANY_VISIBILITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const COMPANY_STATUS = {
  CREATED: 'CREATED',       // company created but not verified yet (nobody can see on the client)
  DELETED: 'DELETED',       // company deleted from the system by admin (nobody can see on the client)
  EXPIRED: 'EXPIRED',       // company verification time expired (nobody can see on the client)
  PENDING: 'PENDING',       // company created and owner send docs for verification (nobody can see on the client)
  VERIFIED: 'VERIFIED',     // company created and verified by admin (everyone can see on the client)
  REJECTED: 'REJECTED',     // rejected by admin after docs check - company docs is not correct (nobody can see on the client)
};

export const COMPANY_DOC_TYPE = {
  ID: 'ID',
  TRADE_LICENSE: 'TRADE_LICENSE',
};

export const USER_STATE = {
  INACTIVE: 'INACTIVE',     // user was deleted from the system
  CREATED: 'CREATED',       // user created but does not finish sign up wizard
  PENDING: 'PENDING',       // user was created pass sign up but does not confirm email
  ACTIVE: 'ACTIVE',         // user was created pass sign up and confirm email
};

export const PAYMENT_STATUS = {
  NEW: 'NEW',
  PAID: 'PAID',
  TRANSACTION_FAIL: 'TRANSACTION_FAIL',
  WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
  TRANSACTION_SUCCESS: 'TRANSACTION_SUCCESS',
};

export const CART_TYPE = {
  EXTRA_VIDEO: 'EXTRA_VIDEO',
  COMPANY_PROMOTION: 'COMPANY_PROMOTION',
  COMPANY_VALIDATE: 'COMPANY_VALIDATE',
  GROUP_PROMOTION: 'GROUP_PROMOTION',
  PROPERTY_PROMOTION: 'PROPERTY_PROMOTION',
  PROPERTY_PROFESSIONAL_SERVICES: 'PROPERTY_PROFESSIONAL_SERVICES',
};

export const VIDEO_REQUEST_STATUS = {
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
};

export const XML_IMPORT_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  PROCESSED: 'PROCESSED',
};
