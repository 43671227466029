
// outsource dependencies
import qs from 'qs';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReCaptchaV2 from 'react-google-recaptcha';
// import ReCaptchaV2Enterprise from 'react-google-recaptcha-enterprise';
import React, { useCallback, memo, useMemo, useEffect, useState } from 'react';

// local dependencies
import { config } from '../constant';
import { ReduxFormControl } from './redux-form';


// eslint-disable-next-line max-len
export const RFCaptcha = memo(function RFCaptcha ({ input, meta, label, classNameFormGroup, className, skipTouch, ...attr }) {
  const { name, value, onChange, onBlur } = input;
  /*********************************************************************
   *                    IMPORTANT !
   * empty RECAPTCHA_SITE_KEY will lead to setting as a captcha error string
   *********************************************************************/
  const [siteKey, setSiteKey] = useState(config('RECAPTCHA_SITE_KEY', 'INVALID_RECAPTCHA_SITE_KEY'));
  /******************************************cac***************************
   *                    IMPORTANT !
   * In order to able disable captcha will be used secret BE key
   * query "disableCaptchaSecret=SecretBEKeyToAllowCaptchaDisable"
   * @example @link https://localhost:3080/sign-in?disableCaptchaSecret=DISABLED_CAPTCHA_SECRET
   *********************************************************************/
  const disableCaptchaSecret = useMemo(
    () => _.get(qs.parse(window.location.search, { ignoreQueryPrefix: true }), 'disableCaptchaSecret', '')
      || config('DISABLE_CAPTCHA_SECRET', ''),
    []
  );
  useEffect(() => {
    if (disableCaptchaSecret) {
      onChange(disableCaptchaSecret);
      setSiteKey(null);
    }
  }, [onChange, disableCaptchaSecret]);
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  const handleError = useCallback(error => {
    onChange(disableCaptchaSecret);
    // NOTE the captcha does not work
    // setSiteKey(null);
    console.error('%c RFCaptcha Error ', 'color: #0A66D7; font-weight: bolder; font-size: 12px;', error);
  }, [onChange, disableCaptchaSecret]);
  // console.log('%c RFCaptcha ', 'color: #0A66D7; font-weight: bolder; font-size: 12px;'
  //   , '\n RECAPTCHA_SITE_KEY:', config('RECAPTCHA_SITE_KEY')
  //   , '\n disableCaptchaSecret:', disableCaptchaSecret
  //   , '\n value:', input.value
  // );
  let message = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
  }
  return siteKey && <ReduxFormControl
    id={name}
    label={label}
    message={message}
    onClick={handleBlur}
    className={cn('rf-captcha', classNameFormGroup)}
  >
    <ReCaptchaV2
      { ...attr }
      id={name}
      sitekey={siteKey}
      onChange={onChange}
      onErrored={handleError}
      className={cn('rf-captcha-control', className)}
    />
  </ReduxFormControl>;
});
RFCaptcha.propTypes = {
  ...ReduxFormControl.propTypes,
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFCaptcha.defaultProps = {
  ...ReduxFormControl.defaultProps,
  className: null,
  skipTouch: false,
  classNameFormGroup: ReduxFormControl.defaultProps.className,
};
