
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from '../../services';
import { IMPORT_PROPERTIES, APP_TITLE, XML_IMPORT_STATUS } from '../../constant';

// configure
export const importPropertiesCtrl = create({
  prefix: 'import-properties',
  actions: {
    importXML: 'IMPORT_XML',
    rejectXML: 'REJECT_XML',
    initialize: 'INITIALIZE',
    updateFilter: 'UPDATE_FILTER',
  },
  initial: {
    list: [],
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filters
    page: 0,
    size: 10,
    search: '',
    sortD: true,
    totalPages: 0,
    sortF: 'createdDate',
    status: XML_IMPORT_STATUS.PENDING,
  },
  subscriber: function * () {
    yield takeEvery(importPropertiesCtrl.action.importXML.TYPE, importXMLSaga);
    yield takeEvery(importPropertiesCtrl.action.rejectXML.TYPE, rejectXMLSaga);
    yield takeEvery(importPropertiesCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(importPropertiesCtrl.action.updateFilter.TYPE, updateFilterSaga);
  }
});

function * initializeSaga () {
  yield put(importPropertiesCtrl.action.clearCtrl());
  const query = yield call(IMPORT_PROPERTIES.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(importPropertiesCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ payload }) {
  yield put(importPropertiesCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, size, sortF, sortD, status } = yield select(importPropertiesCtrl.select);
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      url: '/feed/filter',
      data: { search, status },
      params: { page, size, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] },
    });
    yield put(importPropertiesCtrl.action.updateCtrl({ list: content, totalPages, page: pageNumber }));
    const latest = yield select(importPropertiesCtrl.select);
    yield call(IMPORT_PROPERTIES.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(importPropertiesCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(importPropertiesCtrl.action.updateCtrl({ disabled: false }));
}

function * importXMLSaga ({ type, payload }) {
  yield put(importPropertiesCtrl.action.updateCtrl({ disabled: true }));
  const id = _.get(payload, 'id');
  try {
    yield call(instanceAPI, { method: 'POST', url: `/feed/${id}`, data: {} });
    yield call(toastr.success, APP_TITLE, 'Properties from XML successfully imported!');
    yield put(importPropertiesCtrl.action.initialize({}));
  } catch ({ message }) {
    yield put(importPropertiesCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(importPropertiesCtrl.action.updateCtrl({ disabled: false }));
}

function * rejectXMLSaga ({ type, payload }) {
  yield put(importPropertiesCtrl.action.updateCtrl({ disabled: true }));
  const id = _.get(payload, 'id');
  try {
    yield call(instanceAPI, {
      data: payload,
      method: 'POST',
      url: `/feed/${id}/reject`,
    });
    yield call(toastr.success, APP_TITLE, 'XML successfully rejected!');
    yield put(importPropertiesCtrl.action.initialize({}));
  } catch ({ message }) {
    yield put(importPropertiesCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(importPropertiesCtrl.action.updateCtrl({ disabled: false }));
}
