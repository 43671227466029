
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

// local dependencies
import upload from './upload.svg';
import defImg from './def-image.svg';
import appLogo from './app-logo.svg';
import { config, DIR } from '../constant';
import settingsGif from './settings.gif';
import appLogoSmall from './app-logo-small.svg';
import defaultAvatar from './default_avatar.svg';
import propertyDefault from './property-default.png';
import groupProfileDefault from './group-default.svg';
import companyProfileDefault from './company-default.svg';

const s3RBEPathReg = new RegExp(`/public/(${Object.values(DIR).join('|')})/`);
/**
 * has definitions of BE s3 path and doesn't have the protocol
 * probably we should check exact s3 host, but i do not think so
 * @param url
 * @return {boolean|boolean}
 */
const isS3RelativePath = url => s3RBEPathReg.test(url) && !/^http(s?):\/\//.test(url);
// eslint-disable-next-line max-len
export const DefImage = memo(function DefImage ({ src, defaultSrc, alt, defaultAlt, title, defaultTitle, defaultStyle, style, className, defaultClassName, ...attr }) {
  const validSrc = useMemo(() => {
    if (isS3RelativePath(src)) { return `${config('CLOUD_AWS_BUCKET', '')}${src}`; }
    return src;
  }, [src]);

  return <img
    alt={alt || defaultAlt}
    src={validSrc || defaultSrc}
    title={title || defaultTitle}
    className={cn(defaultClassName, className)}
    style={Object.assign({}, defaultStyle, style)}
    {...attr}
  />;
});
DefImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  defaultSrc: PropTypes.string,
  defaultAlt: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultStyle: PropTypes.object,
  defaultClassName: PropTypes.string,
};
DefImage.defaultProps = {
  src: null,
  alt: null,
  title: null,
  style: {},
  className: null,
  defaultSrc: defImg,
  defaultAlt: 'image',
  defaultClassName: '',
  defaultTitle: '',
  defaultStyle: {},
};

export const Avatar = memo(function Avatar (props) {
  return <DefImage
    defaultAlt="User"
    defaultTitle="User"
    defaultSrc={defaultAvatar}
    defaultStyle={{ borderRadius: '50%' }}
    {...props}
  />;
});

export const CloudImage = memo(function CloudImage (props) {
  return <DefImage
    defaultAlt="Upload to cloud"
    defaultTitle="Upload to cloud"
    defaultSrc={upload}
    {...props}
  />;
});

export const SettingGif = memo(function SettingGif (props) {
  return <DefImage
    defaultAlt="Settings"
    defaultTitle="Settings"
    defaultSrc={settingsGif}
    {...props}
  />;
});

export const AppLogo = memo(function AppLogo (props) {
  return <DefImage
    defaultAlt="Estative logo"
    defaultTitle="Estative logo"
    defaultSrc={appLogo}
    {...props}
  />;
});

export const AppLogoSmall = memo(function AppLogoSmall (props) {
  return <DefImage
    defaultAlt="Estative logo"
    defaultTitle="Estative logo"
    defaultSrc={appLogoSmall}
    {...props}
  />;
});

export const PropertyImage = memo(function PropertyImage (props) {
  return <DefImage
    defaultAlt="Property Cover image"
    defaultTitle="Property Cover image"
    defaultSrc={propertyDefault}
    {...props}
  />;
});

export const CompanyProfileImage = memo(function CompanyProfileImage (props) {
  return <DefImage
    defaultAlt="Company"
    defaultTitle="Company"
    defaultSrc={companyProfileDefault}
    {...props}
  />;
});

export const GroupProfileImage = memo(function GroupProfileImage (props) {
  return <DefImage
    defaultAlt="Group"
    defaultTitle="Group"
    defaultSrc={groupProfileDefault}
    {...props}
  />;
});
