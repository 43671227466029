
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, delay, race, fork } from 'redux-saga/effects';

// local dependencies
import { staticSaga } from './static.saga';
import { store, silence } from './constant';
import { onAuthFailApplicationAction, restoreUserFromStore, signOut, checkAPIHealth } from './services';

// configure
onAuthFailApplicationAction(() => store.dispatch(appRootCtrl.action.signOut()));

export const appRootCtrl = create({
  prefix: 'app',
  actions: {
    initialize: 'INITIALIZE',
    appWarning: 'WARNING',
    signOut: 'SIGN_OUT',
    // static.saga
    download: 'DOWNLOAD',
    getCountries: 'GET_COUNTRIES',
    getBusinessAreas: 'GET_BUSINESS_AREAS',
  },
  initial: {
    initialized: false, // prevent redirect from page and show instead current page and it behavior - global preloader
    health: true,       // prevent redirect from page and show instead current page and it behavior - maintenance page
    user: null,         // logged user information
    roles: [],          // TODO allow to detect available roles of logged users
    countries: [],      // available countries as static app data
    businessAreas: [],  // available business areas as static app data
  },
  subscriber: function * () {
    yield takeEvery(appRootCtrl.action.signOut.TYPE, signOutExe);
    yield takeEvery(appRootCtrl.action.appWarning.TYPE, appWarningExe);
    yield takeEvery(appRootCtrl.action.initialize.TYPE, initializeExe);

    // connect nested sagas
    yield fork(staticSaga);
  }
});

function * initializeExe ({ type, payload }) {
  // NOTE check health of API
  const [healthResponse] = yield race([
    call(silence, checkAPIHealth),
    // NOTE limit checking
    delay(3e4),
  ]);
  const health = _.get(healthResponse, 'status') === 'UP';
  yield put(appRootCtrl.action.updateCtrl({ health }));
  if (!health) {
    // NOTE try again another time
    yield delay(3e4);
    return yield put(appRootCtrl.action.initialize());
  }
  // NOTE trying to restore user
  const [self] = yield race([
    call(silence, restoreUserFromStore),
    // NOTE limit restoring
    delay(5e3),
  ]);
  // console.log('%c initializeExe', 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n type:', type
  //   , '\n self:', self
  //   , '\n health:', health
  //   , '\n payload:', payload
  // );
  // NOTE in case successfully restored self
  if (self) {
    yield put(appRootCtrl.action.updateCtrl({ user: self }));
  }
  // NOTE initialization done
  yield put(appRootCtrl.action.updateCtrl({ initialized: true }));
}

export function * signOutExe ({ type, payload }) {
  // console.log('%c signOutExe ', 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n type:', type
  //   , '\n self:', self
  //   , '\n payload:', payload
  // );
  // NOTE sign out in real we do not need to await answer at all
  yield race([delay(1e3), call(silence, signOut)]);
  // NOTE remove logged user
  yield put(appRootCtrl.action.updateCtrl({ user: null }));
}

export function * appWarningExe ({ type, payload }) {
  const title = _.get(payload, 'title', 'Estative');
  const content = _.isString(payload) ? payload : _.get(payload, 'content', 'Under implementation');

  yield call(toastr.warning, title, content);
}
