
// outsource dependencies
import * as yup from 'yup';
import { Field } from 'redux-form';
import { Link, useParams } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Container, Row, Button, Col } from 'reactstrap';
import React, { memo, useCallback, useEffect } from 'react';

// local dependencies
import { AppLogo } from '../../images';
import { changePassCtrl } from './controller';
import { AlertError, Preloader, Spinner, ReduxForm, RFInput } from '../../component';
import { createYupSyncValidator, FORGOT_PASSWORD, SIGN_IN, VALID } from '../../constant';

// configure
const FORM_NAME = 'changePasswordForm';
const formValidation = createYupSyncValidator(yup.object().shape({
  password: VALID.PASSWORD.required('Password is required'),
  confirmPassword: VALID.CONFIRM_PASSWORD.required('Please repeat the password'),
}));

export const ChangePassword = memo(function ChangePassword () {
  const { token } = useParams();
  const [
    { initialized, disabled, errorMessage, resetToken },
    { initialize, updateData, updateCtrl },
  ] = useController(changePassCtrl);
    // NOTE initialize business logic
  useEffect(() => { initialize({ token }); }, [initialize, token]);
  // NOTE prepare page actions
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);

  return <Container fluid id="ChangePassword" className="change-password">
    <Preloader active={!initialized}>
      <ReduxForm
        form={FORM_NAME}
        onSubmit={updateData}
        validate={formValidation}
      >
        <Row>
          <Col xs="12" className="text-center mb-3">
            <AppLogo className="app-logo" />
          </Col>
          { !resetToken ? <InvalidTokenMessage /> : <>
            <Col xs="12">
              <Field
                name="password"
                type="password"
                component={RFInput}
                disabled={disabled}
                placeholder="Password"
                label={<strong className="required-asterisk"> Password </strong>}
              />
              <Field
                type="password"
                component={RFInput}
                disabled={disabled}
                name="confirmPassword"
                placeholder="Confirm password"
                label={<strong className="required-asterisk"> Confirm Password </strong>}
              />
              <Button
                block
                outline
                type="submit"
                color="primary"
                disabled={disabled}
                className="rounded-pill mb-3"
              >
                Change password
                <Spinner active={disabled} />
              </Button>
              <AlertError active message={errorMessage} onClear={handleClearError}/>
            </Col>
            <Col xs="6" className="mb-3">
              <Link to={SIGN_IN.LINK()}> Sign In </Link>
            </Col>
            <Col xs="6" className="text-right mb-3">
              <Link to={FORGOT_PASSWORD.LINK()}> Forgot Password ? </Link>
            </Col>
          </> }
        </Row>
      </ReduxForm>
    </Preloader>
  </Container>;
});

const InvalidTokenMessage = () => <Col xs="12" tag="h5" className="text-justify text-danger mb-4">
    Whoa there! The request token for this page is invalid.
    It may have already been used, or expired because it is too old.
    Please go back to the
  <Link to={FORGOT_PASSWORD.LINK()}> forgot password page </Link>
    and try again.
  <br/><br/>
  <small className="text-muted"> it was probably just a mistake </small>
</Col>;
