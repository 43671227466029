
/******************************************************************
 * the FontAwesome component might take special annotation
 * it will generate svg image in runtime based on it
 *****************************************************************/
export const envelope = {
  prefix: 'app',
  iconName: 'envelop',
  icon: [
    512,
    512,
    [],
    '',
    `M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48
            96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2
            197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7
            104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z`
  ]
};

export const favHeartFill = {
  prefix: 'app',
  iconName: 'fav-heart-fill',
  icon: [
    512,
    512,
    [],
    '',
    'M 371.2 18 C 326.656 18 283.904 39.017 256 72.209 C 228.096 39.017 185.344 18 140.8 18 C 61.952 18 0 80.777 0 160.672 C 0 258.728 87.04 338.623 218.88 460.021 L 256 494 L 293.12 459.765 C 424.96 338.623 512 258.728 512 160.672 C 512 80.777 450.048 18 371.2 18 Z'
  ]
};

export const favHeartOutline = {
  prefix: 'app',
  iconName: 'fav-heart-outline',
  icon: [
    512,
    512,
    [],
    '',
    'M 371.2 18 C 326.656 18 283.904 39.017 256 72.209 C 228.096 39.017 185.344 18 140.8 18 C 61.952 18 0 80.777 0 160.672 C 0 258.728 87.04 338.623 218.88 460.021 L 256 494 L 293.12 459.765 C 424.96 338.623 512 258.728 512 160.672 C 512 80.777 450.048 18 371.2 18 Z  M 258.56 421.373 L 256 423.955 L 253.44 421.373 C 131.584 309.568 51.2 235.642 51.2 160.672 C 51.2 108.788 89.6 69.884 140.8 69.884 C 180.224 69.884 218.624 95.57 232.192 131.105 L 280.064 131.105 C 293.376 95.57 331.776 69.884 371.2 69.884 C 422.4 69.884 460.8 108.788 460.8 160.672 C 460.8 235.642 380.416 309.568 258.56 421.373 Z'
  ]
};

export const send = {
  prefix: 'app',
  iconName: 'send',
  icon: [
    20,
    20,
    [],
    '',
    'M1.6763 17.5L19.168 10L1.6763 2.5L1.66797 8.33333L14.168 10L1.66797 11.6667L1.6763 17.5Z'
  ]
};

export const arrowDropdownCircle = {
  prefix: 'app',
  iconName: 'arrow-dropdown-circle',
  icon: [
    24,
    24,
    [],
    '',
    'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 14L8 10H16L12 14Z'
  ]
};

export const edit = {
  prefix: 'app',
  iconName: 'edit',
  icon: [
    24,
    24,
    [],
    '',
    'M20.0276 8.78184C20.6763 8.13025 21.0006 7.23433 21.0006 6.3384C21.0006 5.36102 20.5141 4.46509 19.7844 3.81351C18.406 2.59179 16.2979 2.75469 15.0817 3.97641L13.2168 5.84971L13.9465 6.58274L17.3519 10.0036L18.0817 10.7366L20.0276 8.78184ZM8.75624 20.1043L17.3508 11.4709L16.7022 10.7378L13.2968 7.31702L12.5671 6.58398L4.05354 15.2989C3.64814 15.7062 3.40489 16.1948 3.32381 16.6835L3.08057 18.3125C2.91841 19.0455 3.16165 19.7786 3.72922 20.3487C4.13462 20.8374 4.78327 21.0817 5.43192 21.0817H5.75624L7.37786 20.8374C7.86435 20.7559 8.35084 20.5116 8.75624 20.1043Z',
  ]
};

export const trash = {
  prefix: 'app',
  iconName: 'trash',
  icon: [
    24,
    24,
    [],
    '',
    'M13.5862 3H10.4151C9.76642 3 9.33398 3.5339 9.33398 4.14407H14.6673C14.6673 3.5339 14.1628 3 13.5862 3ZM18.9189 5.28906H5.08108C4.5045 5.28906 4 5.74669 4 6.35686C4 6.96703 4.5045 7.50093 5.08108 7.50093H18.9189C19.4955 7.50093 20 6.96703 20 6.35686C20 5.74669 19.4955 5.28906 18.9189 5.28906ZM9.33272 21.0005C8.612 21.0005 7.96335 20.3903 7.8192 19.6276L5.22461 8.64453H18.7021L16.1075 19.6276C15.9633 20.3903 15.3147 21.0005 14.594 21.0005H9.33272Z'
  ]
};

export const people = {
  prefix: 'app',
  iconName: 'people',
  icon: [
    32,
    32,
    [],
    '',
    'M 18.054 10.309 C 18.054 7.24 15.609 4.666 12.48 4.666 C 9.448 4.666 6.906 7.24 6.906 10.309 C 6.906 13.378 9.351 15.951 12.48 15.951 C 15.511 15.951 18.054 13.477 18.054 10.309 Z  M 18.054 18.82 L 6.906 18.82 C 3.874 18.82 1.332 21.295 1.332 24.463 C 1.332 26.047 2.603 27.334 4.168 27.334 L 20.792 27.334 C 22.356 27.334 23.628 26.047 23.628 24.463 C 23.628 21.394 21.183 18.82 18.054 18.82 Z  M 19.912 4.666 C 19.129 4.666 18.347 4.864 17.662 5.161 C 20.303 7.834 20.205 12.784 17.565 15.456 C 18.249 15.753 19.032 15.951 19.912 15.951 C 23.041 15.951 25.486 13.378 25.486 10.309 C 25.486 7.24 23.041 4.666 19.912 4.666 Z  M 22.845 18.82 L 25.094 18.82 C 28.126 18.82 30.668 21.295 30.668 24.463 C 30.668 26.047 29.397 27.334 27.832 27.334 L 24.312 27.334 C 26.072 23.968 25.094 20.899 22.845 18.82 Z'
  ]
};

export const groups = {
  prefix: 'app',
  iconName: 'groups',
  icon: [
    32,
    32,
    [],
    '',
    `M1.93,23.69A.55.55,0,0,0,2,23.54a1.66,1.66,0,0,1,1.1-1.45l2.1-1a1.08,1.08,0,0,0,.73-.86.46.46,0,0,0-.14-.34A4.15,4.15,0,0,1,4.44,18a.44.44,0,0,0-.2-.23A1.11,1.11,0,0,1,4.06,16a.51.51,0,0,0,.11-.32c0-.47,0-.95.12-1.41a.9.9,0,0,1,.62-.75.21.21,0,0,0,.12-.1,1.5,1.5,0,0,1,1.31-.86,4.68,4.68,0,0,1,2.43.24,2.06,2.06,0,0,1,1.39,1.78,6.85,6.85,0,0,1,.07.82.93.93,0,0,0,.26.77,1.12,1.12,0,0,1-.34,1.59A.4.4,0,0,0,10,18a3.77,3.77,0,0,1-1.31,1.86.4.4,0,0,0-.1.56A5,5,0,0,0,9,21c-.19.15-.42.32-.63.5a3.11,3.11,0,0,0-1.19,2.57c0,.51,0,1,0,1.56H3.76a3.45,3.45,0,0,1-.68-.07A1.25,1.25,0,0,1,2,24.36a1.37,1.37,0,0,0-.07-.23Z
    M23.91,24.2a10.71,10.71,0,0,1-.1,1.28,1.31,1.31,0,0,1,0,.16H8.29a1.35,1.35,0,0,1-.11-.38,8.87,8.87,0,0,1,0-1.45A2.38,2.38,0,0,1,9.29,22a13.29,13.29,0,0,1,2.95-1.46c.41-.16.82-.33,1.2-.53a1.41,1.41,0,0,0,.75-1A.46.46,0,0,0,14,18.5a5.58,5.58,0,0,1-1.84-2.71.47.47,0,0,0-.21-.25,1.65,1.65,0,0,1-.19-2.5c.08,0,.12-.25.11-.38a5.33,5.33,0,0,1,.13-2,1.44,1.44,0,0,1,.91-1.15.24.24,0,0,0,.13-.13,1.92,1.92,0,0,1,1.58-1.16,5.79,5.79,0,0,1,3.94.51A2.66,2.66,0,0,1,20,10.9c.08.61.08,1.22.13,1.83,0,.1,0,.24.09.29a1.32,1.32,0,0,1,.49,1.32A1.4,1.4,0,0,1,20,15.58a.36.36,0,0,0-.14.21A5.48,5.48,0,0,1,18,18.48a.51.51,0,0,0-.18.58,1.39,1.39,0,0,0,.8,1c.68.31,1.38.58,2.07.87A9,9,0,0,1,23,22.16,2.38,2.38,0,0,1,23.91,24.2Z
    M23,21a3.11,3.11,0,0,0,.36-.21.82.82,0,0,0,.25-.27c.21-.45.12-.61-.35-.71A3,3,0,0,1,21.73,19c-.13-.13-.18-.25,0-.42a1.28,1.28,0,0,0,.23-1.24,10.3,10.3,0,0,1-.3-1.31,2.66,2.66,0,0,1,1.59-2.82.44.44,0,0,0,.23-.18,1,1,0,0,1,.65-.47,3.14,3.14,0,0,1,3.78,2.33,3.6,3.6,0,0,1-.14,2.26,1.27,1.27,0,0,0,.21,1.43.24.24,0,0,1,0,.38,3,3,0,0,1-1.55.77c-.15,0-.41.1-.42.16a1.64,1.64,0,0,0,.11.65c0,.09.17.16.27.21l1.87.86.55.25a2,2,0,0,1,1.31,2,3.55,3.55,0,0,1-.08.79,1.25,1.25,0,0,1-1.28.93H25c-.21,0-.22-.08-.21-.27a12.89,12.89,0,0,0,0-1.92,3.25,3.25,0,0,0-1.45-2.21L23,21S23,21,23,21Z`,
  ]
};

export const home = {
  prefix: 'app',
  iconName: 'home',
  icon: [
    32,
    32,
    [],
    '',
    'M 30 13.085 L 30 25.88 C 30 27.794 28.41 29.348 26.454 29.348 L 22.908 29.348 C 20.952 29.348 19.362 27.794 19.362 25.88 L 19.362 20.738 C 19.362 19.782 18.629 19.064 17.651 19.064 L 14.227 19.064 C 13.249 19.064 12.515 19.782 12.515 20.738 L 12.515 25.88 C 12.515 27.794 10.926 29.348 8.969 29.348 L 5.546 29.348 C 3.59 29.348 2 27.794 2 25.88 L 2 13.085 C 2 12.009 2.611 10.933 3.467 10.215 L 13.983 3.28 C 15.205 2.442 16.673 2.442 17.895 3.28 L 28.41 10.215 C 29.389 10.933 30 12.009 30 13.085 Z'
  ]
};


export const company = {
  prefix: 'app',
  iconName: 'company',
  icon: [
    26,
    24,
    [],
    '',
    'M4.37 22.8H0v-7.58l4.37-2.66V22.8zM23.18 17.74v5.06l-4.13.21V8.44l1.41 1.73v7.57h2.72zM25.03 22.8h-1.42v-4.81l1.42 1.27v3.54zM5.07 0h5.25v1.11H5.07zM5.07 1.97h5.25v1.11H5.07zM5.07 3.94h5.25v1.11H5.07zM5.07 5.91h5.25v1.11H5.07zM5.07 7.89h5.25V9H5.07zM5.07 9.86h5.25v1.11H5.07zM5.07 11.83h5.25v1.11H5.07zM5.07 13.8h5.25v1.11H5.07zM5.07 15.77h5.25v1.11H5.07zM5.07 17.74h5.25v1.11H5.07zM5.07 19.71h5.25v1.11H5.07zM5.07 21.69h5.25v1.24H5.07zM18.42 9V7.89h-4.93V2.07L10.91.28v22.65h7.51v-1.24h-4.93v-.87h4.93v-1.11h-4.93v-.86h4.93v-1.11h-4.93v-.86h4.93v-1.11h-4.93v-.86h4.93V13.8h-4.93v-.86h4.93v-1.11h-4.93v-.86h4.93V9.86h-4.93V9h4.93z',
  ]
};

export const star = {
  prefix: 'app',
  iconName: 'star',
  icon: [
    24,
    24,
    [],
    '',
    'M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z'
  ]
};

export const comment = {
  prefix: 'app',
  iconName: 'comment',
  icon: [
    25,
    24,
    [],
    '',
    'M19.9783 2H5.02174C3.63043 2 2.5 3.12069 2.5 4.5V18.1207C2.5 19.5 3.63043 20.6207 5.02174 20.6207H16.413C17.1087 20.6207 17.7174 20.8793 18.1522 21.3103C19.1087 22.2586 20.6739 22.2586 21.7174 21.3103C22.1522 20.8793 22.413 20.1897 22.413 19.5862V4.5C22.5 3.12069 21.3696 2 19.9783 2ZM7.45652 12.5172C6.76087 12.5172 6.23913 12 6.23913 11.3103C6.23913 10.6207 6.84783 10.0172 7.45652 10.0172C8.15217 10.0172 8.67391 10.5345 8.67391 11.2241C8.76087 12 8.15217 12.5172 7.45652 12.5172ZM12.5 12.5172C11.8043 12.5172 11.2826 12 11.2826 11.3103C11.2826 10.6207 11.8043 10.1034 12.5 10.1034C13.1957 10.1034 13.7174 10.6207 13.7174 11.3103C13.7174 12 13.1957 12.5172 12.5 12.5172ZM17.4565 12.5172C16.7609 12.5172 16.2391 12 16.2391 11.3103C16.2391 10.6207 16.7609 10.1034 17.4565 10.1034C18.1522 10.1034 18.6739 10.6207 18.6739 11.3103C18.7609 12 18.1522 12.5172 17.4565 12.5172Z'
  ]
};

export const videoCamera = {
  prefix: 'app',
  iconName: 'video-camera',
  icon: [
    24,
    24,
    [],
    '',
    'M20.1931 7.60369C19.6966 7.43779 19.1379 7.54839 18.7655 7.88018L16.469 9.92627V7.21659C16.469 6.553 15.8483 6 15.1034 6H4.36552C3.62069 6 3 6.553 3 7.21659V16.8387C3 17.5023 3.62069 18.0553 4.36552 18.0553H15.1655C15.9103 18.0553 16.531 17.5023 16.531 16.8387V14.129L18.8276 16.1751C19.0759 16.3963 19.4483 16.5069 19.7586 16.5069C19.9448 16.5069 20.131 16.4516 20.2552 16.3963C20.7517 16.2304 21.0621 15.788 21.0621 15.2903V8.70968C21 8.26728 20.6897 7.82488 20.1931 7.60369ZM11.5034 12.47L8.77241 14.2949C8.64828 14.3502 8.52414 14.4055 8.4 14.4055C8.27586 14.4055 8.21379 14.4055 8.08966 14.3502C7.84138 14.2396 7.71724 14.0737 7.71724 13.8525V10.1475C7.71724 9.92627 7.84138 9.70507 8.08966 9.64977C8.33793 9.53917 8.58621 9.53917 8.77241 9.70507L11.4414 11.53C11.6276 11.6406 11.6897 11.8065 11.6897 11.9723C11.7517 12.1935 11.6276 12.3594 11.5034 12.47Z'
  ]
};
