
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import React, { useCallback, memo, useEffect } from 'react';

// local dependencies
import { ReduxFormControl } from './redux-form';

// eslint-disable-next-line max-len
export const RFInputPhone = memo(function RFInput ({ input, type, meta, label, skipTouch, classNameFormGroup, className, clearOnUnmount, ...attr }) {
  const { name, onChange, onBlur, value } = input;
  // NOTE controlled feature to clearing redux form field value on component unmount
  useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);

  // NOTE prepare input actions
  const handleBlur = useCallback(value => onBlur(value), [onBlur]);
  const handleChange = useCallback(value => onChange(value), [onChange]);

  // NOTE handle valid/invalid state and error message for input
  let message = '', statusClass = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
    statusClass += meta.valid ? ' is-valid' : ' is-invalid';
  }
  return <ReduxFormControl
    id={name}
    label={label}
    message={message}
    className={cn('rf-input-phone', classNameFormGroup)}
  >
    <PhoneInput
      { ...attr }
      { ...input }
      id={name}
      type="tel"
      value={value}
      autoFormat={false}
      onBlur={handleBlur}
      onChange={handleChange}
      className={cn('rf-input-phone-field', statusClass, className)}
    />
  </ReduxFormControl>;
});
RFInputPhone.propTypes = {
  ...ReduxFormControl.propTypes,
  type: PropTypes.string,
  skipTouch: PropTypes.bool,
  country: PropTypes.string,
  className: PropTypes.string,
  clearOnUnmount: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFInputPhone.defaultProps = {
  ...ReduxFormControl.defaultProps,
  type: 'text',
  country: 'ae',
  className: '',
  skipTouch: false,
  clearOnUnmount: false,
  classNameFormGroup: ReduxFormControl.defaultProps.className,
};
