
// outsource dependencies
import _ from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Table, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// local dependencies
import { paymentsListCtrl } from './controller';
import { CART_TYPE, PAYMENT_STATUS } from '../../constant';
import { formatPrice, humanize, formatFullName } from '../../services';
import { Preloader, AlertError, SearchInput, SortBy, Pagination, PageSize } from '../../component';

// configure
const pageSizeOptions = [10, 20, 30];

export const PaymentsList = memo(function PaymentsList () {
  const [
    { initialized, errorMessage, disabled, list, sortD, sortF, size, page, search, totalPages,
      cartType, status },
    { initialize, updateCtrl, updateFilter }
  ] = useController(paymentsListCtrl);
  useEffect(() => { initialize(); }, [initialize]);

  const isEmpty = _.isEmpty(list);
  const prepared = useMemo(() => _.map(list, item => ({
    ...item,
    clientName: formatFullName(item),
    status: humanize(_.get(item, 'status')),
    type: humanize(_.get(item, 'cartType')),
    date: dayjs(_.get(item, 'updatedDate')).format('MMM D, YYYY h:mm A'),
    amount: formatPrice(_.get(item, 'amount') / 100, _.get(item, 'currency')),
  })), [list]);

  const cartTypes = useMemo(() => Object.values(CART_TYPE).map(item => ({
    id: item,
    name: humanize(item),
    onClick: () => updateFilter({ page: 0, cartType: item })
  })), [updateFilter]);
  const paymentStatuses = useMemo(() => Object.values(PAYMENT_STATUS).map(item => ({
    id: item,
    name: humanize(item),
    onClick: () => updateFilter({ page: 0, status: item })
  })), [updateFilter]);

  // NOTE prepare actions
  const handleChangePage = useCallback(page => updateFilter({ page }), [updateFilter]);
  const handleChangeSize = useCallback(size => updateFilter({ size }), [updateFilter]);
  const handleChangeSearch = useCallback(search => updateCtrl({ search }), [updateCtrl]);
  const handleSort = useCallback(sort => updateFilter({ page: 0, ...sort }), [updateFilter]);
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);
  const handleClear = useCallback(() => updateFilter({ page: 0, search: '' }), [updateFilter]);
  const handleApply = useCallback(() => updateFilter({ page: 0, search }), [updateFilter, search]);

  return <Container fluid id="PaymentsList" className={cn('payments-list', { 'no-events': disabled })} >
    <Preloader active={!initialized} className="app-preloader">
      <AlertError className="animated fadeIn mb-3" active message={errorMessage} onClear={handleClearError} />
      <Row>
        <Col xs="12" tag="h2" className="text-primary mb-3"> Payments </Col>
      </Row>
      <Row className="display-flex align-items-center border-bottom mb-3 pb-2">
        <Col xs="3">
          <SearchInput
            value={search}
            onApply={handleApply}
            onClear={handleClear}
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs="4" className="d-flex justify-content-center">
          <UncontrolledDropdown size="lg">
            <DropdownToggle caret> { humanize(cartType) } </DropdownToggle>
            <DropdownMenu>
              {/* eslint-disable-next-line max-len */}
              { _.map(cartTypes, ({ id, name, onClick }) => <DropdownItem id={id} onClick={onClick} disabled={id === cartType}>
                { name }
              </DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col xs="3" className="d-flex justify-content-center">
          <UncontrolledDropdown size="lg">
            <DropdownToggle caret> { humanize(status) } </DropdownToggle>
            <DropdownMenu>
              {/* eslint-disable-next-line max-len */}
              { _.map(paymentStatuses, ({ id, name, onClick }) => <DropdownItem id={id} onClick={onClick} disabled={id === status}>
                { name }
              </DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col xs="2" className="d-flex justify-content-start">
          <PageSize size="lg" options={pageSizeOptions} value={size} disabled={disabled} onChange={handleChangeSize} />
        </Col>
      </Row>
      <Table bordered striped>
        <thead>
          <tr>
            <th>
              <SortBy field="firstName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Client name </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="cartType" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Cart type </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="entityName" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Name </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="status" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Status </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="updatedDate" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Date </strong>
              </SortBy>
            </th>
            <th>
              <SortBy field="amount" sortF={sortF} sortD={sortD} onChange={handleSort}>
                <strong className="text-primary"> Price </strong>
              </SortBy>
            </th>
          </tr>
        </thead>
        <tbody>
          { isEmpty ? <tr>
            <td colSpan="5">
              <Alert color="chrome" className="text-center font-weight-bold"> No results found </Alert>
            </td>
          </tr>
            : _.map(prepared, ({ id, type, status, clientName, date, amount, entityName }) => <tr key={id}>
              <td className="text-truncate"> { clientName } </td>
              <td className="text-truncate"> { type } </td>
              <td className="text-truncate"> { entityName } </td>
              <td className="text-truncate"> { status } </td>
              <td className="text-truncate"> { date } </td>
              <td className="text-truncate"> { amount } </td>
            </tr>) }
        </tbody>
      </Table>
      <Row>
        <Col xs="12" className="text-center">
          <Pagination
            value={page}
            totalPages={totalPages}
            className="d-inline-block"
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
