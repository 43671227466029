
// outsource dependencies
import * as yup from 'yup';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Container, Row, Button, Col } from 'reactstrap';
import React, { memo, useCallback, useEffect } from 'react';

// local dependencies
import { AppLogo } from '../../images';
import { signInCtrl, FORM_NAME } from './controller';
import { createYupSyncValidator, VALID, FORGOT_PASSWORD } from '../../constant';
import { AlertError, Spinner, ReduxForm, RFInput, RFCaptcha } from '../../component';

// configure
const formValidation = createYupSyncValidator(yup.object().shape({
  password: VALID.STRING.required('Password is required'),
  username: VALID.STRING.required('Login is required'),
}));

export const SignIn = memo(function SignIn () {
  const [
    { disabled, errorMessage },
    { initialize, signIn, updateCtrl }
  ] = useController(signInCtrl);
    // NOTE initialize business logic
  useEffect(() => { initialize(); }, [initialize]);
  // NOTE prepare page actions
  const handleClearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);

  return <Container fluid id="SignIn" className="sign-in">
    <ReduxForm
      form={FORM_NAME}
      onSubmit={signIn}
      validate={formValidation}
    >
      <Row>
        <Col xs="12" className="text-center mb-3">
          <AppLogo className="app-logo" />
        </Col>
        <Col xs="12">
          <Field
            type="text"
            name="username"
            component={RFInput}
            disabled={disabled}
            placeholder="Email Address"
          />
          <Field
            name="password"
            type="password"
            component={RFInput}
            disabled={disabled}
            placeholder="Password"
          />
          <Field name="captcha" disabled={disabled} component={RFCaptcha} />
          <Button
            block
            outline
            type="submit"
            color="primary"
            disabled={disabled}
            className="mb-3 rounded-pill"
          >
            <span> LOGIN </span>
            <Spinner active={disabled} />
          </Button>
          <AlertError active message={errorMessage} onClear={handleClearError}/>
        </Col>
        {/* <Col xs="12" className="text-center mb-3">
          <Link to={SIGN_UP.LINK()}>
            <strong> Sign Up </strong>
          </Link>
        </Col> */}
        <Col xs="12" className="text-center mb-3">
          <Link to={FORGOT_PASSWORD.LINK()}>
            <strong> Forgot your password ? </strong>
          </Link>
        </Col>
      </Row>
    </ReduxForm>
  </Container>;
});
