
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { appRootCtrl } from '../../controller';
import { silence, SIGN_IN, APP_TITLE } from '../../constant';
import { instanceAPI, setupSession, getSelf } from '../../services';

// configure
export const changePassCtrl = create({
  prefix: 'change-password',
  actions: {
    initialize: 'INITIALIZE',
    updateData: 'UPDATE_DATA'
  },
  initial: {
    disabled: false,
    resetToken: null,
    initialized: false,
    errorMessage: null,
    isTokenValid: false,
  },
  subscriber: function * () {
    yield takeEvery(changePassCtrl.action.initialize.TYPE, silence, initializeSaga);
    yield takeEvery(changePassCtrl.action.updateData.TYPE, silence, updateDataSaga);
  }
});

function * initializeSaga ({ type, payload }) {
  // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n payload:', payload
  // );
  try {
    const resetToken = _.get(payload, 'token');
    yield call(instanceAPI, {
      url: '/users/set-password/validate',
      data: { resetToken },
      method: 'POST',
    });
    yield put(changePassCtrl.action.updateCtrl({ resetToken }));
  } catch ({ message }) {
    yield put(changePassCtrl.action.updateCtrl({ errorMessage: message }));
  }
  // NOTE do nothing
  yield put(changePassCtrl.action.updateCtrl({ initialized: true, ...payload }));
}

function * updateDataSaga ({ type, payload }) {
  // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
  //   , '\n payload:', payload
  // );
  yield put(changePassCtrl.action.updateCtrl({ disabled: true, errorMessage: null }));
  const { resetToken } = yield select(changePassCtrl.select);
  try {
    const session = yield call(instanceAPI, {
      method: 'POST',
      url: '/users/set-password',
      data: { resetToken, password: _.get(payload, 'password') }
    });
    yield call(setupSession, session);
    const self = yield call(getSelf);
    yield put(appRootCtrl.action.updateCtrl({ user: self }));
    yield call(toastr.success, APP_TITLE, 'We are glad to see you again !');
    yield call(SIGN_IN.PUSH, {});
  } catch ({ message }) {
    yield call(toastr.error, 'Error', message);
    yield put(changePassCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(changePassCtrl.action.updateCtrl({ disabled: false }));
}
