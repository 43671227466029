
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { GROUPS, APP_TITLE } from '../../constant';
import { instanceAPI, SweetAlert } from '../../services';

// configure
export const groupsListCtrl = create({
  prefix: 'groups-list',
  actions: {
    initialize: 'INITIALIZE',
    deleteItem: 'DELETE_ITEM',
    updateFilter: 'UPDATE_FILTER',
  },
  initial: {
    list: [],
    disabled: false,
    initialized: false,
    errorMessage: null,
    // filter
    page: 0,
    size: 10,
    search: '',
    sortD: false,
    totalPages: 0,
    sortF: 'memberCount',
  },
  subscriber: function * () {
    yield takeEvery(groupsListCtrl.action.initialize.TYPE, initializeSaga);
    yield takeEvery(groupsListCtrl.action.deleteItem.TYPE, deleteItemSaga);
    yield takeEvery(groupsListCtrl.action.updateFilter.TYPE, updateFilterSaga);
  }
});

function * initializeSaga () {
  yield put(groupsListCtrl.action.clearCtrl());
  const query = yield call(GROUPS.QUERY);
  yield call(updateFilterSaga, { payload: { ...query } });
  yield put(groupsListCtrl.action.updateCtrl({ initialized: true }));
}

function * updateFilterSaga ({ payload }) {
  yield put(groupsListCtrl.action.updateCtrl({ ...payload, disabled: true }));
  try {
    const { page, search, size, sortF, sortD, } = yield select(groupsListCtrl.select);
    // TODO Wait BE implementation
    const { content, totalPages, pageNumber } = yield call(instanceAPI, {
      method: 'POST',
      data: { search },
      url: 'groups/user-groups-filter',
      params: { size, page, sort: [`${sortF},${sortD ? 'DESC' : 'ASC'}`] }
    });
    yield put(groupsListCtrl.action.updateCtrl({ list: _.uniqBy(content, 'id'), totalPages, page: pageNumber }));
    const latest = yield select(groupsListCtrl.select);
    yield call(GROUPS.REPLACE, {}, latest);
  } catch ({ message }) {
    yield put(groupsListCtrl.action.updateCtrl({ errorMessage: message }));
  }
  yield put(groupsListCtrl.action.updateCtrl({ disabled: false }));
}

function * deleteItemSaga ({ payload }) {
  const id = _.get(payload, 'id');
  const title = _.get(payload, 'title');
  try {
    const confirmation = yield call(SweetAlert.confirm, {
      title: `Are you sure you want to delete group: ${title}?`
    });
    if (!confirmation.value) { return; }
    yield put(groupsListCtrl.action.updateCtrl({ disabled: true }));
    // TODO wait BE implementation
    yield call(instanceAPI, { method: 'DELETE', url: '/groups', data: [id] });
    yield call(toastr.success, APP_TITLE, 'Group successfully deleted!');
    yield put(groupsListCtrl.action.initialize({}));
  } catch ({ message }) {
    yield call(SweetAlert.error, { title: message });
  }
  yield put(groupsListCtrl.action.updateCtrl({ disabled: false }));
}
