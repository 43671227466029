
// outsource dependencies
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import React, { useCallback, memo, useEffect, useMemo } from 'react';

// local dependencies
import { ReduxFormControl } from './redux-form';

// eslint-disable-next-line max-len
export const RFInput = memo(function RFInput ({ input, type, meta, label, skipTouch, classNameFormGroup, filter, format, className, clearOnUnmount, ...attr }) {
  const { name, onChange, onBlur, value } = input;
  // NOTE controlled feature to clearing redux form field value on component unmount
  useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);
  // NOTE prepare input actions
  const handleBlur = useCallback(event => onBlur(filter(event.target.value)), [onBlur, filter]);
  const handleChange = useCallback(event => onChange(filter(event.target.value)), [onChange, filter]);
  // NOTE ability to format view value
  const val = useMemo(() => format(value), [format, value]);
  // NOTE handle valid/invalid state and error message for input
  let message = '', statusClass = '';
  if (skipTouch || meta.touched) {
    message = meta.error;
    statusClass += meta.valid ? ' is-valid' : ' is-invalid';
  }
  return <ReduxFormControl
    id={name}
    label={label}
    message={message}
    className={cn('rf-input', classNameFormGroup)}
  >
    <Input
      dir="auto"
      type={type}
      autoComplete="off"
      { ...attr }
      { ...input }
      id={name}
      value={val}
      onBlur={handleBlur}
      onChange={handleChange}
      className={cn('rf-input-field', statusClass, className)}
    />
  </ReduxFormControl>;
});
RFInput.propTypes = {
  ...ReduxFormControl.propTypes,
  type: PropTypes.string,
  format: PropTypes.func,
  filter: PropTypes.func,
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  clearOnUnmount: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  classNameFormGroup: ReduxFormControl.propTypes.className,
};
RFInput.defaultProps = {
  ...ReduxFormControl.defaultProps,
  type: 'text',
  className: '',
  format: e => e,
  filter: e => e,
  skipTouch: false,
  clearOnUnmount: false,
  classNameFormGroup: ReduxFormControl.defaultProps.className,
};
