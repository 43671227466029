
// outsource dependencies
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';
import React, { memo, useCallback, useMemo } from 'react';

// local dependencies
import { RFSelect } from './index';
import { useBusinessAreas } from '..//hook';

// configure
const getValue = item => _.get(item, 'id', null);
const getLabel = item => _.get(item, 'name', null);
const useFormValues = formName => useSelector(getFormValues(formName)) || {};
const useClearSelected = (formName, field) => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(change(formName, field, null, false)), [dispatch, formName, field]);
};
// eslint-disable-next-line max-len
export const RFBusinessAreas = memo(function RFBusinessAreas ({ form, areaField, detailsField, disabled, className, clearOnUnmount, skipTouch, ...attr }) {
  const aries = useBusinessAreas();
  const isAreasLoading = !_.size(aries);
  const formValues = useFormValues(form);
  const area = _.get(formValues, areaField);
  const areaId = _.get(area, 'id');
  const clearSelectedDetails = useClearSelected(form, detailsField);
  // NOTE list of "details" from selected "areas"
  const details = useMemo(
    () => _.get(_.find(aries, ({ id: areaId })), 'userBusinessAreaDetails') || [],
    [areaId, aries]
  );

  return <div className={cn('rf-business-areas', className)} { ...attr }>
    <Field
      options={aries}
      name={areaField}
      disabled={disabled}
      component={RFSelect}
      skipTouch={skipTouch}
      placeholder="Category"
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      isLoading={isAreasLoading}
      clearOnUnmount={clearOnUnmount}
      onChange={clearSelectedDetails}
    />
    <Field
      isMulti
      options={details}
      name={detailsField}
      component={RFSelect}
      skipTouch={skipTouch}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      placeholder="Subcategory"
      disabled={disabled || !areaId}
      clearOnUnmount={clearOnUnmount}
    />
  </div>;
});
RFBusinessAreas.propTypes = {
  skipTouch: PropTypes.bool,
  className: PropTypes.string,
  clearOnUnmount: PropTypes.bool,
  form: PropTypes.string.isRequired,
  areaField: PropTypes.string.isRequired,
  detailsField: PropTypes.string.isRequired,
};
RFBusinessAreas.defaultProps = {
  className: '',
  skipTouch: false,
  clearOnUnmount: false,
};
