
// outsource dependencies
import React, { memo, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useController } from 'redux-saga-controller';

// local dependencies
import { AppLogo } from '../../images';
import { SIGN_IN } from '../../constant';
import { Preloader } from '../../component';
import { emailConfirmCtrl } from './controller';

export const EmailConfirmation = memo(function EmailConfirmation () {
  const { token } = useParams();
  const [
    { initialized, isTokenValid },
    { initialize },
  ] = useController(emailConfirmCtrl);
    // NOTE initialize business logic
  useEffect(() => { initialize({ token }); }, [initialize, token]);

  return <Container fluid id="EmailConfirmation" className="email-confirmation">
    <Preloader active={!initialized}>
      <Row className="align-items-center">
        <Col xs="12" className="text-center mb-3">
          <AppLogo className="app-logo" />
        </Col>
        <Col xs="12" tag="h3" className="text-center mb-4">
            Email verification
        </Col>
        { !isTokenValid ? <Col
          xs="12"
          tag="h5"
          className="text-justify text-danger mb-4"
        >
            Whoa there! The request token for this page is invalid.
            It may have already been used, or expired because it is too old.
            Please go back and try again.
          <br/><br/>
          <small className="text-muted"> it was probably just a mistake </small>
        </Col> : <Col
          xs="12"
          tag="h4"
          className="text-center text-success mb-4"
        >
            The email address was successfully verified. Welcome aboard !
        </Col>}
        <Col xs="12" className="text-right mb-3">
          <Link to={SIGN_IN.LINK()}> Sign In </Link>
        </Col>
      </Row>
    </Preloader>
  </Container>;
});
