
// outsource dependencies
import _ from 'lodash';
import * as yup from 'yup';
import { Field } from 'redux-form';
import React, { memo, useCallback } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from 'reactstrap';

// local dependencies
import { appRootCtrl } from '../../controller';
import { propertiesListCtrl } from './controller';
import { ReduxForm, RFInput } from '../../component';
import { VALID, createYupSyncValidator } from '../../constant';

// configure
const formValidation = createYupSyncValidator(yup.object().shape({
  message: VALID.STRING.required('Reject reason is mandatory if you want to reject property verification'),
}));

export const Verify = memo(function Verify () {
  const { download } = useControllerActions(appRootCtrl);
  const { verify } = useControllerData(propertiesListCtrl);
  const { verifyProperty } = useControllerActions(propertiesListCtrl);

  const isOpen = Boolean(verify);
  const IDDocs = _.get(verify, 'IDDocs', []);
  const titleDeedDocs = _.get(verify, 'titleDeedDocs', []);

  // NOTE prepare actions
  const handleClose = useCallback(() => verifyProperty(null), [verifyProperty]);
  const handleVerify = useCallback(() => verifyProperty({ verify: true }), [verifyProperty]);

  return <Modal id="Verify" isOpen={isOpen} toggle={handleClose}>
    <Button close onClick={handleClose} className="position-absolute p-3" style={{ right: 0 }} />
    <ModalHeader> Verify property </ModalHeader>
    <ModalBody>
      <Row>
        <Col tag="h5"> User Passport or ID </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3">
          { _.map(IDDocs, ({ id, name }) => <Button
            id={id}
            size="sm"
            color="none"
            className="text-primary text-nowrap mr-3"
            onClick={() => download({ url: `/properties/document/${id}`, fileName: name })}
          >
            download { name }
          </Button>)}
        </Col>
      </Row>
      <Row>
        <Col tag="h5"> Title Deed or ownership certificate </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-3">
          { _.map(titleDeedDocs, ({ id, name }) => <Button
            id={id}
            size="sm"
            color="none"
            className="text-primary text-nowrap mr-3"
            onClick={() => download({ url: `/properties/document/${id}`, fileName: name })}
          >
            download { name }
          </Button>)}
        </Col>
      </Row>
      <ReduxForm onSubmit={verifyProperty} form="REJECT_REASON" validate={formValidation}>
        <Field type="text" name="message" component={RFInput} placeholder="Reject reason..." />
        <Button outline type="submit" color="danger" className="rounded-pill">
          Reject
        </Button>
      </ReduxForm>
    </ModalBody>
    <ModalFooter>
      <Button type="button" color="primary" onClick={handleVerify} className="rounded-pill">
        Verify
      </Button>
    </ModalFooter>
  </Modal>;
});
