
// outsource dependencies
import _ from 'lodash';
import { takeLeading, put, call, select, delay } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from './services';
import { appRootCtrl } from './controller';
import { silence, config } from './constant';

// configure
export function * staticSaga () {
  // NOTE listen
  yield takeLeading(appRootCtrl.action.download.TYPE, silence, downloadExe);
  yield takeLeading(appRootCtrl.action.getCountries.TYPE, silence, getCountriesExe);
  yield takeLeading(appRootCtrl.action.getBusinessAreas.TYPE, silence, getBusinessAreasExe);
}

function * downloadExe ({ type: actType, payload }) {
  const url = _.get(payload, 'url');
  const filename = _.get(payload, 'filename', `file-${Date.now()}`);
  config('DEBUG') && console.info(`%c SAGA ${actType} `, 'color: #FF6766; font-size: 16px;'
    , '\n payload:', payload
  );
  // NOTE important to send empty data object @see {@link https://github.com/axios/axios/issues/86}
  const blobData = yield call(instanceAPI, { method: 'GET', url, responseType: 'blob', data: {} });
  const type = _.get(payload, 'type', 'application/octet-stream');
  const blob = new Blob([blobData], { type });
  const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob)
    : window.webkitURL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = blobURL;
  link.setAttribute('download', filename);
  typeof link.download === 'undefined' && link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  yield delay(2e2);
  // NOTE Fixes "webkit blob resource error 1"
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobURL);
}


function * getCountriesExe () {
  const { countries: current } = yield select(appRootCtrl.select);
  if (_.get(current, 'cached')) { return null; }
  const { content: countries } = yield call(instanceAPI, {
    url: '/country',
    method: 'POST',
    data: { name: '' },
    params: { page: 0, size: 512 },
  });
  countries.cached = true;
  yield put(appRootCtrl.action.updateCtrl({ countries }));
}

function * getBusinessAreasExe () {
  const { businessAreas: current } = yield select(appRootCtrl.select);
  if (_.get(current, 'cached')) { return null; }
  const businessAreas = yield call(instanceAPI, { url: '/user-business-area', method: 'GET' });
  businessAreas.cached = true;
  yield put(appRootCtrl.action.updateCtrl({ businessAreas }));
}
