
// outsource dependencies
import _ from 'lodash';
import React, { memo, useCallback } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// local dependencies
import { Avatar } from '../../images';
import { humanize } from '../../services';
import { USER_EDIT } from '../../constant';
import { appRootCtrl } from '../../controller';
import { privateLayoutCtrl } from './controller';
import { UerCogIcon, SignOutIcon } from '../../component';
import { useSelfAvatar, useSelfName, useSelfId } from '../../hook';

export const UserMenu = memo(function UserMenu () {
  const selfId = useSelfId();
  const selfName = useSelfName();
  const avatar = useSelfAvatar();
  const { roles } = useControllerData(privateLayoutCtrl);
  const { signOut } = useControllerActions(appRootCtrl);

  const handleToggleSetting = useCallback(() => USER_EDIT.PUSH({ id: selfId }), [selfId]);

  return <UncontrolledDropdown nav inNavbar className="user-menu">
    <DropdownToggle nav className="pt-2 pb-2">
      <strong> { selfName } </strong>&nbsp;
      <Avatar src={avatar} alt={selfName} className="avatar" />
    </DropdownToggle>
    <DropdownMenu right className="animated flipInX">
      <DropdownItem header>
        <span className="d-flex align-items-center">
          <span> Roles: </span>
          { _.map(roles, (role, i) => <span key={i} className="badge badge-danger ml-auto">
            { humanize(role) }
          </span>) }
        </span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={handleToggleSetting}>
        <div className="media">
          <div className="align-self-start mr-3">
            <UerCogIcon size="2x" className="text-primary" />
          </div>
          <div className="media-body">
            <p className="m-0"> Settings </p>
            <p className="m-0 text-muted text-sm"> My profile </p>
          </div>
        </div>
      </DropdownItem>
      <DropdownItem onClick={signOut}>
        <div className="media">
          <div className="align-self-start mr-3">
            <SignOutIcon size="2x" className="text-danger" />
          </div>
          <div className="media-body">
            <p className="m-0"> Sign out </p>
            <p className="m-0 text-muted text-sm"> Destroy current session </p>
          </div>
        </div>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>;
});
