
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Avatar } from '../../../images';
import React, { memo, useCallback } from 'react';
import { useControllerData, useControllerActions } from 'redux-saga-controller';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, CustomInput } from 'reactstrap';

// local dependencies
import { companyEditCtrl } from './controller';
import { SearchInput } from '../../../component';
import { formatFullName } from '../../../services';

export const InviteMembersModal = memo(function InviteMembersModal () {
  const { members } = useControllerData(companyEditCtrl);
  const { updateCtrl, loadUsers, inviteMembersSubmit } = useControllerActions(companyEditCtrl);

  const users = _.get(members, 'list');
  const isOpen = _.get(members, 'isOpen');
  const search = _.get(members, 'search');
  const isAdmin = _.get(members, 'isAdmin');
  const selected = _.get(members, 'selected');

  // NOTE prepare actions
  const handleLoadMore = useCallback(() => loadUsers({}), [loadUsers]);
  const handleApply = useCallback(() => loadUsers({ page: 0 }), [loadUsers]);
  const handleInvite = useCallback(() => inviteMembersSubmit({}), [inviteMembersSubmit]);
  const handleClose = useCallback(() => updateCtrl({ members: { isOpen: false } }), [updateCtrl]);
  // eslint-disable-next-line max-len
  const handleChangeSearch = useCallback(search => updateCtrl({ members: { ...members, search } }), [members, updateCtrl]);
  const handleClear = useCallback(() => {
    updateCtrl({ members: { ...members, page: 0, search: '' } });
    loadUsers({});
  }, [members, updateCtrl, loadUsers]);

  return <Modal id="InviteMembersModal" isOpen={isOpen} toggle={handleClose}>
    <Button close onClick={handleClose} className="position-absolute p-3" style={{ right: 0 }} />
    <ModalHeader> Invite { isAdmin ? 'Admins' : 'Members' } </ModalHeader>
    <ModalBody>
      <SearchInput
        value={search}
        className="mb-3"
        onApply={handleApply}
        onClear={handleClear}
        onChange={handleChangeSearch}
      />
    </ModalBody>
    <ModalBody>
      <div className="user-list mb-3">
        { _.map(users, item => <UserItem key={item.id} { ...item } className="mb-3" />) }
      </div>
      <Button onClick={handleLoadMore} size="lg" color="none" className="mb-3">
        load more
      </Button>
    </ModalBody>
    <ModalFooter>
      <Button
        color="danger"
        onClick={handleClose}
        className="rounded-pill"
      >
        Close
      </Button>
      <Button
        color="primary"
        onClick={handleInvite}
        className="rounded-pill"
        disabled={!_.size(selected)}
      >
        Invite
      </Button>
    </ModalFooter>
  </Modal>;
});

const useSelectMember = () => {
  const { members } = useControllerData(companyEditCtrl);
  const { updateCtrl } = useControllerActions(companyEditCtrl);

  const selected = _.get(members, 'selected', []);

  const toggleSelect = id => {
    let updated;
    if (_.includes(selected, id)) {
      updated = _.filter(selected, itemId => itemId !== id);
    } else {
      updated = selected.concat(id);
    }

    updateCtrl({ members: { ...members, selected: updated } });
  };

  return [selected, toggleSelect];
};

export const UserItem = memo(function UserItem ({ id, firstName, lastName, profilePictureUri }) {
  const [selected, toggleSelect] = useSelectMember();
  const checked = _.includes(selected, id);
  const fullName = formatFullName({ firstName, lastName });
  const handleSelect = useCallback(() => toggleSelect(id), [toggleSelect, id]);

  return <label htmlFor={id} className="user-item">
    <CustomInput
      id={id}
      type="checkbox"
      checked={checked}
      onChange={handleSelect}
      className="checkbox mr-2"
    />
    <Avatar src={profilePictureUri} className="avatar mr-2" />
    <h6 className="name m-0"> { fullName } </h6>
  </label>;
});
UserItem.propTypes = {
  id: PropTypes.string.isRequired,
  profilePictureUri: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};
UserItem.defaultProps = {
  profilePictureUri: null,
};
