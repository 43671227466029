
// outsource dependencies
import _ from 'lodash';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isPristine, Field } from 'redux-form';
import { useController } from 'redux-saga-controller';
import { Container, Row, Col, Button } from 'reactstrap';
import React, { memo, useEffect, useCallback } from 'react';

// local dependencies
import { InviteMembersModal } from './invite-members';
import { filterUrl, humanize } from '../../../services';
import { companyEditCtrl, FORM_NAME } from './controller';
import { COMPANY_EDIT, createYupSyncValidator, COMPANY_LIST, VALID, COMPANY_VISIBILITY, COMPANY_POLICY } from '../../../constant';
import { Preloader, ReduxForm, AlertError, RFLocation, RFInput, RFBusinessAreas, RFInputPhone, RFSelect } from '../../../component';

// configure
const getValue = item => _.get(item, 'value');
const getLabel = item => _.get(item, 'label', '...');
const POLICY_OPTIONS = Object.values(COMPANY_POLICY).map(item => ({ value: item, label: humanize(item) }));
const VISIBILITY_OPTIONS = Object.values(COMPANY_VISIBILITY).map(item => ({ value: item, label: humanize(item) }));
const formValidation = createYupSyncValidator(yup.object().shape({
  name: VALID.TITLE.required('Please provide company name'),

  businessArea: VALID.ENTITY.required('Please select "Category"'),
  businessAreaDetails: VALID.ENTITY_LIST.when('businessArea', {
    is: businessArea => _.get(businessArea, 'isMultipleDetailsOptions'),
    then: VALID.ENTITY_LIST.max(4, 'You can select no more than 4 categories from the list').required('Please select "Subcategories"'),
    otherwise: VALID.ENTITY_LIST.max(1, 'You can select only 1 category from the list').required('Please select "Subcategory"')
  }),

  webAddress: VALID.URL.required('Please provide company website'),

  country: VALID.ENTITY.required('Please select the Country'),
  city: VALID.ENTITY.required('Please select the City'),

  email: VALID.EMAIL.required('Please provide company email'),
  adminPhoneNumber: VALID.PHONE.required('Admin phone number is mandatory'),
  description: VALID.DESCRIPTION.required('Please provide company description'),

  visibility: VALID.STRING.required('Please select company visibility'),
  policy: VALID.STRING.required('Please select company join policy'),
}));

export const CompanyEdit = memo(function CompanyEdit () {
  const [
    { initialized, company, isNew, disabled, errorMessage },
    { initialize, updateCompany, inviteMembersOpen }
  ] = useController(companyEditCtrl);
  const { id } = COMPANY_EDIT.PARAMS();
  const pristine = useSelector(isPristine(FORM_NAME));

  useEffect(() => { initialize({ id }); }, [initialize, id]);

  // NOTE prepare actions
  const handleInviteMembers = useCallback(() => inviteMembersOpen({}), [inviteMembersOpen]);
  const handleInviteAdmins = useCallback(() => inviteMembersOpen({ isAdmin: true }), [inviteMembersOpen]);

  return <Container id="CompanyEdit" className="company-edit">
    <Preloader active={!initialized} className="app-preloader">
      <InviteMembersModal />
      <Row>
        <Col xs="12" className="mb-3">
          <h2> { isNew ? 'Create' : 'Edit' } Company </h2>
        </Col>
      </Row>
      { !isNew && <Row className="mb-3">
        <Col xs="6">
          <Button
            block
            color="primary"
            className="rounded-pill"
            onClick={handleInviteMembers}
          >
            Invite members
          </Button>
        </Col>
        <Col xs="6">
          <Button
            block
            color="primary"
            className="rounded-pill"
            onClick={handleInviteAdmins}
          >
            Invite admins
          </Button>
        </Col>
      </Row> }
      <ReduxForm
        form={FORM_NAME}
        initialValues={company}
        onSubmit={updateCompany}
        validate={formValidation}
      >
        <Row>
          <Col xs="12">
            <Field
              type="text"
              name="name"
              disabled={disabled}
              component={RFInput}
              placeholder="Company name"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <RFBusinessAreas
              clearOnUnmount
              form={FORM_NAME}
              disabled={disabled}
              areaField="businessArea"
              detailsField="businessAreaDetails"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              type="text"
              filter={filterUrl}
              name="webAddress"
              disabled={disabled}
              component={RFInput}
              placeholder="Web address"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <RFLocation
              clearOnUnmount
              cityField="city"
              form={FORM_NAME}
              disabled={disabled}
              countryField="country"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              type="email"
              name="email"
              disabled={disabled}
              component={RFInput}
              placeholder="Official email"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              type="tel"
              disabled={disabled}
              name="adminPhoneNumber"
              component={RFInputPhone}
              label={<span className="text-muted"> Company admin contact number </span>}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              isSimple
              name="visibility"
              disabled={disabled}
              component={RFSelect}
              getOptionLabel={getLabel}
              getOptionValue={getValue}
              options={VISIBILITY_OPTIONS}
              placeholder="Company visibility"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              isSimple
              name="policy"
              disabled={disabled}
              component={RFSelect}
              options={POLICY_OPTIONS}
              getOptionLabel={getLabel}
              getOptionValue={getValue}
              placeholder="Company join policy"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              rows="5"
              type="textarea"
              name="description"
              disabled={disabled}
              component={RFInput}
              placeholder="Company description"
            />
          </Col>
        </Row>
        <AlertError message={errorMessage} />
        <Row className="mb-3">
          <Col xs="12" className="d-flex justify-content-between">
            <Button
              tag={Link}
              color="danger"
              disabled={disabled}
              to={COMPANY_LIST.LINK()}
              className="rounded-pill mr-2"
            >
              BACK
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-pill"
              disabled={disabled || pristine}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </ReduxForm>
    </Preloader>
  </Container>;
});
