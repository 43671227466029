// outsource dependencies

// local dependencies
import * as ROUTES from '../constant/route';
import { MENU_ITEM_TYPE } from './layout/sidebar-menu';

export const MENU = [
  {
    key: 'welcome',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Welcome screen',
    icon: ['app', 'star'],
    link: ROUTES.WELCOME_SCREEN.LINK(),
    isActive: location => ROUTES.WELCOME_SCREEN.REGEXP.test(location.pathname)
  },
  {
    key: 'users',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Users',
    icon: ['app', 'people'],
    link: ROUTES.USERS.LINK(),
    isActive: location => ROUTES.USERS.REGEXP.test(location.pathname)
  },
  {
    key: 'properties',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Properties',
    icon: ['app', 'home'],
    link: ROUTES.PROPERTIES.LINK(),
    isActive: location => ROUTES.PROPERTIES.REGEXP.test(location.pathname)
  },
  {
    key: 'import-properties',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Import Properties',
    icon: ['fas', 'file-import'],
    link: ROUTES.IMPORT_PROPERTIES.LINK(),
    isActive: location => ROUTES.IMPORT_PROPERTIES.REGEXP.test(location.pathname)
  },
  {
    key: 'video-request',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Video Requests',
    icon: ['app', 'video-camera'],
    link: ROUTES.VIDEO_REQUESTS.LINK(),
    isActive: location => ROUTES.VIDEO_REQUESTS.REGEXP.test(location.pathname)
  },
  {
    key: 'companies',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Companies',
    icon: ['app', 'company'],
    link: ROUTES.COMPANIES.LINK(),
    isActive: location => ROUTES.COMPANIES.REGEXP.test(location.pathname)
  },
  // {
  //   key: 'groups',
  //   type: MENU_ITEM_TYPE.LINK,
  //   name: 'Groups',
  //   icon: ['app', 'groups'],
  //   link: ROUTES.GROUPS.LINK(),
  //   isActive: location => ROUTES.GROUPS.REGEXP.test(location.pathname)
  // },
  // {
  //   key: 'posts',
  //   type: MENU_ITEM_TYPE.LINK,
  //   name: 'Posts',
  //   link: ROUTES.POSTS.LINK(),
  //   icon: ['fas', 'pen-square'],
  //   isActive: location => ROUTES.POSTS.REGEXP.test(location.pathname)
  // },
  {
    key: 'payments',
    type: MENU_ITEM_TYPE.LINK,
    name: 'Payments',
    link: ROUTES.PAYMENTS.LINK(),
    icon: ['fas', 'shopping-cart'],
    isActive: location => ROUTES.PAYMENTS.REGEXP.test(location.pathname)
  },
  // {
  //   key: 'comments',
  //   type: MENU_ITEM_TYPE.LINK,
  //   name: 'Comments',
  //   link: ROUTES.COMMENTS.LINK(),
  //   icon: ['app', 'comment'],
  //   isActive: location => ROUTES.COMMENTS.REGEXP.test(location.pathname)
  // },
];

export default MENU;
