
// outsource dependencies
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { memo, useEffect } from 'react';
import { Field, isPristine } from 'redux-form';
import { useController } from 'redux-saga-controller';
import { Container, Row, Col, Button } from 'reactstrap';

// local dependencies
import { userEditCtrl } from './controller';
import { humanize } from '../../../services';
import { USER_EDIT, createYupSyncValidator, VALID, ROLES, USER_LIST } from '../../../constant';
import { Preloader, ReduxForm, AlertError, RFInput, RFSelect, RFLocation } from '../../../component';

// configure
const FORM_NAME = 'EDIT_USER';
const rolesOptions = Object.values(ROLES).map(role => ({ value: role, label: humanize(role) }));
const formValidation = createYupSyncValidator(yup.object().shape({
  firstName: VALID.NAME.required('First name is mandatory'),
  emailAddress: VALID.EMAIL.required('Email is mandatory'),
  lastName: VALID.NAME.required('Last name is mandatory'),
  roles: VALID.ENTITY_LIST.required('Role is mandatory'),
}));

export const UserEdit = memo(function UserEdit () {
  const [
    { initialized, initial, isNew, disabled, errorMessage },
    { initialize, updateUser }
  ] = useController(userEditCtrl);
  const { id } = USER_EDIT.PARAMS();
  const pristine = useSelector(isPristine(FORM_NAME));

  useEffect(() => { initialize({ id }); }, [initialize, id]);

  return <Container id="UserEdit" className="user-edit">
    <Preloader active={!initialized} className="app-preloader">
      <Row>
        <Col xs="12" className="mb-3">
          <h2> { isNew ? 'Create' : 'Edit' } User </h2>
        </Col>
      </Row>
      <ReduxForm
        form={FORM_NAME}
        onSubmit={updateUser}
        initialValues={initial}
        validate={formValidation}
      >
        <Row>
          <Col xs="12">
            <Field
              type="text"
              name="firstName"
              component={RFInput}
              disabled={disabled}
              placeholder="First name"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              type="text"
              name="lastName"
              component={RFInput}
              disabled={disabled}
              placeholder="Last name"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              type="email"
              name="emailAddress"
              component={RFInput}
              disabled={disabled}
              placeholder="Email"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Field
              isMulti
              skipTouch
              name="roles"
              placeholder="Roles"
              disabled={disabled}
              component={RFSelect}
              options={rolesOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <RFLocation
              isSimple
              clearOnUnmount
              form={FORM_NAME}
              cityField="cityId"
              disabled={disabled}
              countryField="countryId"
            />
          </Col>
        </Row>
        <AlertError message={errorMessage} />
        <Row className="mb-3">
          <Col xs="12" className="d-flex justify-content-between">
            <Button
              tag={Link}
              color="danger"
              disabled={disabled}
              to={USER_LIST.LINK()}
              className="rounded-pill mr-2"
            >
              BACK
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-pill"
              disabled={disabled || pristine}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </ReduxForm>
    </Preloader>
  </Container>;
});
